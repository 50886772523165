import { CAlert, CCol, CCollapse, CForm, CFormInput, CFormLabel, CFormSwitch, CFormTextarea, CLoadingButton, CPlaceholder } from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../libs/api'
import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import { useEffect, useState } from 'react';
import useModalDelete from '../../hooks/modalDelete';
import moment from 'moment';
import RiDatePicker from '../../components/inputs/DatePicker';
import InputImages from '../cashflow/componenet.input.images';
import { cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {GenericErrorHandler} from "../../libs/misc";

export function FormAddEdit({ edit, title }: { edit?: string, title: string }) {
    const [collapseVisible, setCollapseVisible] = useState(false)
    const [addToast] = useToast()
    const navigate = useNavigate();
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const FormSchema = z.object({
        inventory: z.string().nullable(),
        keterangan: z.string().nullable(),
        date_beli: z.string().nullable(),
        is_active: z.boolean().optional(),
        images: z.array(z.number()),
        message: z.void()
    });

    type FormSchemaType = z.infer<typeof FormSchema>;
    const initial = {
        is_active: true,
        inventory: null,
        keterangan: null,
        date_beli: null,
        images: [],
        akun_kredit_pinjaman_id: null,
        akun_debit_pelunasan_id: null,
        akun_debit_bunga_id: null,
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        control,
        reset,
        watch
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: (edit !== undefined
            ? initial
            : { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(initial)) }
        )
    })

    const instance = watch()

    useEffect(() => {
        if (edit === undefined) {
            localStorage.setItem(title, JSON.stringify(instance))
        }
    }, [edit, instance, title])

    useEffect(() => {
        if (edit !== undefined) {
            setIsRetrievingData(true)
            api.get(`/inventories/${edit}`).then(onfulfilled => {
                if (onfulfilled.data) {
                    reset(onfulfilled.data)
                }
                setIsRetrievingData(false)
            }).catch(reason => {
                GenericErrorHandler({reason, addToast})
            })
        } else {
            setIsRetrievingData(false)
        }
    }, [addToast, edit, reset])

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("message" | "inventory" | "keterangan" | "date_beli" | "is_active" | "images"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/inventories', data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
            reset(initial)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.put(`/inventories/${edit}`, data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const [UiModalDelete, UiButtonDelete] = useModalDelete({
        title: `Delete Inventory #${edit}`,
        path: `/inventories/${edit}`,
        confirmation: <>Saya yakin ingin menghapus <strong>Inventory #{edit}</strong></>
    })

    if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

    return (<>

        {errors.message &&
            <CAlert color="danger">
                {errors.message?.message}
            </CAlert>
        }

        <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
            autoComplete='false'
        >
            <CFormLabel htmlFor="inventory" className="col-md-2 col-form-label">Inventory</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Inventory'
                    invalid={errors.inventory != null}
                    feedbackInvalid={errors.inventory?.message as string}
                    {...register('inventory')}
                />
            </CCol>
            <CFormLabel htmlFor="keterangan" className="col-md-2 col-form-label">Keterangan</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormTextarea
                    placeholder='Keterangan'
                    invalid={errors.keterangan != null}
                    feedbackInvalid={errors.keterangan?.message as string}
                    {...register('keterangan')}
                />
            </CCol>

            <CFormLabel htmlFor="date_beli" className="col-md-2 col-form-label">Date Beli</CFormLabel>
            <CCol md={10} className='mb-3'>
                <Controller
                    name="date_beli"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <RiDatePicker
                            id={'date'}
                            placeholder={'Date Beli'}
                            invalid={error != null}
                            selected={value ? new Date(moment(value).format('YYYY-MM-DD')) : null}
                            feedbackInvalid={error?.message}
                            onChange={(e) => {
                                if (e != null) {
                                    onChange((moment(e).format('YYYY-MM-DD') || null))
                                } else {
                                    onChange(null)
                                }

                            }}
                        />
                    )}
                />
            </CCol>
            <CCol xs={12} className='text-center mb-3'>
                <span style={{ cursor: 'pointer' }} onClick={() => {
                    setCollapseVisible(x => !x)
                }}>
                    <CIcon icon={collapseVisible ? cisChevronBottom : cisChevronTop} />
                </span>
            </CCol>
            <CCol>
                <CCollapse className='row' visible={collapseVisible}>
                    <CFormLabel htmlFor="images" className="col-md-2 col-form-label">Images</CFormLabel>
                    <CCol md={10} className='mb-3' >
                        <Controller
                            name="images"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <InputImages
                                    values={value || []}
                                    onChange={images => {
                                        onChange(images)
                                    }}
                                />
                            )}
                        />
                    </CCol>
                    <CCol md={10} className='offset-md-2 mb-3' >
                        <CFormSwitch
                            label="Aktif"
                            invalid={errors.is_active != null}
                            {...register('is_active')}
                        />
                    </CCol>
                </CCollapse>
            </CCol>

            <CCol md={10} className='mb-3 offset-md-2'>
                <CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
                <CLoadingButton color='secondary' className='me-2' onClick={() => {
                    reset(initial)
                }}>Clear</CLoadingButton>
                {edit && <UiButtonDelete />}
            </CCol>
        </CForm>
        {edit && <UiModalDelete />}
    </>)
}
