import { useState } from 'react'

export default function useToggle(x:boolean) {
    const [on, setOn] = useState(x)

    const toggle = () => {
        setOn(x => !x)
    }

    return {on, toggle}
}
