import { useContext, useEffect, useRef, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import api from '../../libs/api';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { CPlaceholder } from '@coreui/react-pro';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { IReportGraph } from '../../types/entities/report';
import useToast from '../../hooks/toast';
import {GenericErrorHandler} from "../../libs/misc";
import { AppContext } from '../../contexts/App';

export default function Graph() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IReportGraph[]>([])
    const [loading, setloading] = useState(true)
    const navigate = useNavigate();
    const chartRef = useRef<ChartJSOrUndefined<"bar", (number | [number, number] | null)[], unknown>>();
    const [addToast] = useToast()
    const { eye } = useContext(AppContext)

    useEffect(() => {
        setloading(true)
        api.get(`reports/graph?${searchParams.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const chart_data: ChartData<'bar'> = {
        labels: data.map(x => `${x.label}`) || [],
        datasets: [
            {
                label: 'Kredit',
                data: data.map(x => (eye === 'close' ? 100 : x.kredit)) || [],
                backgroundColor: '#e55353',
                hoverBackgroundColor: '#e96d6d',
                stack: 'Stack 0',
            },
            {
                label: 'Debit',
                data: data.map(x => (eye === 'close' ? 100 : x.debit)) || [],
                backgroundColor: '#2eb85c',
                hoverBackgroundColor: '#4dc374',
                stack: 'Stack 1',

            },
        ],

    }

    const chart_options: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    footer: (tooltipItems) => {
                        return [
                            `Start: ${data[tooltipItems[0].dataIndex].min}`,
                            `End: ${data[tooltipItems[0].dataIndex].max}`,
                        ];
                    }
                }
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                display: true,
                grid: {
                    display: true,
                },
                ticks: {
                    display: true,
                },
                stacked: true,
            },
            y: {
                display: true,
                grid: {
                    display: true,
                },
                ticks: {
                    display: true,
                },
                stacked: true,
            },
        },
        indexAxis: 'y' as const,
        interaction: {
            mode: 'y' as const,
            intersect: false,
        },
        onHover: (event, element) => {
            (event?.native?.target as HTMLElement).style.cursor = element.length > 0 ? 'pointer' : 'auto'
        },
        // onClick: (event, element) => {
        //     if (element.length > 0) {
        //         const selected = data[element[0].index]
        //         const sp = createSearchParams(searchParams)
        //         sp.delete('rule')
        //         sp.set('tanggal_after', selected.min)
        //         sp.set('tanggal_before', selected.max)
        //         navigate({
        //             pathname: '/cashflow',
        //             search: sp.toString()
        //         })
        //     }
        // }
    }

    if (loading) return (<CPlaceholder animation="glow">
        <CPlaceholder xs={12} size="lg" />
    </CPlaceholder>)
    else return (
        <Bar
            ref={chartRef}
            height={(data.length * 50) + 50}
            // width={data.length * 70}
            data={chart_data}
            options={chart_options}
            onClick={(event) => {
                const element = getElementAtEvent(chartRef.current as ChartJS<'bar'>, event)
                if (element.length > 0) {
                    const selected = data[element[0].index]
                    const sp = createSearchParams(searchParams)
                    sp.delete('rule')
                    sp.set('date_after', selected.min)
                    sp.set('date_before', selected.max)
                    sp.set('is_active', 'true' )
                    sp.set('ordering', '-nominal' )
                    navigate({
                        pathname: '/cashflow',
                        search: sp.toString()
                    })
                }
            }}
        />
    )
}
