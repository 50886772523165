import React from "react";

export function GeneratePassword() {
    var length = 20,
        charset = `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&*-=+_`,
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    if(!/[!@#$%&*-=+_]/g.test(retVal) || !/^[A-Z]/.test(retVal) || !/\d+/g.test(retVal)) {
        retVal = GeneratePassword()
      }
    return retVal;
}

export function GenericErrorHandler({reason, addToast, additionalErrorHandler} : {
    reason: any,
    addToast : (value: React.SetStateAction<{message: string, color: "danger" | "success" | "info" | "warning" | "dark"}>) => void,
    additionalErrorHandler? : (reason?: any) => void}
){
    if (reason.code === "ERR_NETWORK") {
        addToast({ message: reason.message, color: "danger" })
    } else {
        if(reason.response.status === 401)
            localStorage.removeItem('token')
        try {
            if(additionalErrorHandler)
                additionalErrorHandler(reason);
            addToast({ message: reason.response.data.message, color: "danger" })
        } catch {
            addToast({ message: "Undefined Error", color: "danger" })
        }
    }
}