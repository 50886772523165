import { CFormFeedback } from "@coreui/react-pro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function RiDatePicker({
    id,
    placeholder = "",
    selected = null,
    className = "",
    onChange,
    invalid = false,
    size = 'md',
    filterDate,
    isClearable = false,
    feedbackInvalid
}: {
    id?: string
    placeholder?: string
    selected?: Date | null | undefined
    className?: string
    onChange: (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void
    invalid?: boolean
    size?: 'sm' | 'md',
    filterDate?: (date: Date) => boolean
    isClearable?: boolean,
    feedbackInvalid? : string
}) {

    return (<>
        <DatePicker
            id={id}
            placeholderText={placeholder}
            wrapperClassName={`w-100 ${(invalid ? 'is-invalid' : '')}`}
            className={['form-control', `form-control-${size}`, (invalid ? 'is-invalid' : ''), className].join(" ")}
            selected={selected}
            onChange={onChange}
            dateFormat="yyyy-MM-dd"
            autoComplete={'off'}
            filterDate={filterDate}
            isClearable={isClearable}
        />
        <CFormFeedback invalid>{feedbackInvalid}</CFormFeedback></>
    )
}
