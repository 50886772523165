import { CCol, CRow} from '@coreui/react-pro'
import { useContext, useEffect } from 'react'

import { AppContext } from '../../contexts/App'
import WidgetAkun from './component.widget.akun'
import WidgetInventory from './component.widget.inventory'
import WidgetBukuPembantu from './component.widget.bukupembantu'
import WidgetCashFlow from './component.widget.cashflow'
import WidgetHutang from './component.widget.hutang'
import WidgetPiutang from './component.widget.piutang'
import WidgetUser from './component.widget.user'

export default function Index() {
	const { setMetaData } = useContext(AppContext)

	useEffect(() => {
		setMetaData({
			title: "Dashboard",
		})
	}, [setMetaData])
	
	return (<>
		<CRow>
			<CCol lg={6}>
				<WidgetUser />
			</CCol>
			<CCol lg={6}>
				<WidgetCashFlow />
			</CCol>
			<CCol lg={3} xs={6}>
				<WidgetAkun />
			</CCol>
			<CCol lg={3} xs={6}>
				<WidgetBukuPembantu />
			</CCol>
			<CCol lg={6}>
				<WidgetInventory />
			</CCol>
			<CCol lg={6}>
				<WidgetHutang />
			</CCol>
			<CCol lg={6}>
				<WidgetPiutang />
			</CCol>
		</CRow>
	</>)
}
