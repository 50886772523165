import {cilContrast, cilMoon, cilSun} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle} from "@coreui/react-pro";
import { useContext } from "react";
import { AppContext } from "../contexts/App";

export default function HeaderDropdownModeToggle() {
    const { mode,setMode, theme } = useContext(AppContext)
    return (
        <>
            <CDropdown className="me-2" variant="nav-item">
                <CDropdownToggle caret={false} className={'h-100'}>
                    <CIcon size={'xxl'} icon={mode === 'light' ? cilSun : (mode === 'dark' ? cilMoon : cilContrast)} className={'align-middle h-100'} />
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem style={{cursor: 'pointer'}} active={mode === 'light'} onClick={() => {
                        setMode('light')
                    }}>
                        <CIcon icon={cilSun} className="me-2" /> Light
                    </CDropdownItem>
                    <CDropdownItem style={{cursor: 'pointer'}} active={mode === 'dark'} onClick={() => {
                        setMode('dark')
                    }}>
                        <CIcon icon={cilMoon} className="me-2" /> Dark
                    </CDropdownItem>
                    <CDropdownItem style={{cursor: 'pointer'}} active={mode === null} onClick={() => {
                        setMode(null)
                    }}>
                        <CIcon icon={cilContrast} className="me-2" /> Auto
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </>
    )
}
