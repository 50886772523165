import { CButton, CCard, CCardBody, CCardHeader, CCol, CCollapse, CRow } from '@coreui/react-pro'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../contexts/App'
import { FormAddEdit } from './componenet.form.add.edit'
import CIcon from '@coreui/icons-react'
import { cisCaretBottom, cisCaretTop } from '@coreui/icons-pro'
import { IOption } from '../../types/inputs/select'
import RiSelect from "../../components/inputs/Select";

export default function Add() {
    const { setMetaData, theme } = useContext(AppContext)
    const title = `Add Cash Flow`
    const [collapseTemplate, setCollapseTemplate] = useState(false)

    const [template, setTemplate] = useState<number|undefined>(undefined)

    useEffect(() => {
        setMetaData({ title })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol md={12}>
            <CButton active={collapseTemplate} color={'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    setCollapseTemplate(x => !x)
                }}> Template<CIcon className='ms-1' icon={collapseTemplate ? cisCaretBottom : cisCaretTop} /></CButton>
            </CCol>
        </CRow>
        <CCollapse visible={collapseTemplate}>
            <CRow className='mb-3'>
                <CCol>
                    <CCard>
                        <CCardBody>
                            <RiSelect
                                type={"template"}
                                isSub={'false'}
                                placeholder='Select Template'
                                // feedbackInvalid={error?.message}
                                // invalid={error != null}
                                value={template || undefined}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        const data = newValue as IOption
                                        setTemplate(data.value as number)
                                    } else {
                                        setTemplate(undefined)
                                    }
                                }}
                                theme={theme}
                            />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCollapse>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <FormAddEdit
                            title={title}
                            template={template}
                        />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </>)
}
