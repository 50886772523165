
import { useNavigate } from 'react-router-dom'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CPlaceholder, CWidgetStatsD } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { cilBank } from '@coreui/icons-pro';
import { useRetrieve } from '../../hooks/retrieve';
import { useContext } from 'react';
import { AppContext } from '../../contexts/App';

interface IWidgetPiutang {
    data_count: number
    inactive_count: number
    summary: {
        total_pinjaman__sum: string
        total_pelunasan__sum: string
        total_profit__sum: string
    }
}

export default function WidgetPiutang() {
    const navigate = useNavigate()
    const [instance] = useRetrieve<IWidgetPiutang>({ path: "/dashboards/piutang" })
    const { eye } = useContext(AppContext)

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Legend,
        Title,
        Tooltip
    );
    if (!instance) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<CWidgetStatsD
        style={{ cursor: 'pointer' }}
        onClick={() => {
            navigate({
                pathname: '/piutang'
            })
        }}
        className="mb-3"
        icon={<><CIcon icon={cilBank} className="my-3 text-white" height={40} /> <span className="text-white ms-3">PIUTANG</span></>}
        color={'primary'}
        values={[
            { title: 'pinjaman', value: `Rp ${eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(Number(instance?.summary.total_pinjaman__sum) || 0)}` },
            { title: 'pelunasan', value: `Rp ${eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(Number(instance?.summary.total_pelunasan__sum) || 0)}` },
            { title: 'profit', value: `Rp ${eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(Number(instance?.summary.total_profit__sum) || 0)}` },
        ]}
        chart={
            <Bar
                className="position-absolute w-100 h-100"
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            display: false,
                        },
                    },
                }}

                data={{
                    labels: ['Pinjaman', 'Pelunasan', 'Profit'],
                    datasets: [
                        {
                            data: [
                                Number(eye === 'close' ? 100: instance?.summary?.total_pinjaman__sum),
                                Number(eye === 'close' ? 100: instance?.summary?.total_pelunasan__sum),
                                Number(eye === 'close' ? 100: instance?.summary?.total_profit__sum)
                            ],
                            borderColor: 'rgba(255,255,255,.55)',
                            backgroundColor: 'rgba(255,255,255,.1)',
                        },
                    ],
                }}
            />
        }
    />)
}
