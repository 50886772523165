import axios from "axios";
import {IToken} from "../types/token";

const api = axios.create({
    baseURL: `${process.env.REACT_APP_PUBLIC_API_URL}`
})

api.interceptors.request.use(
    function (config) {
        const token : IToken = JSON.parse(localStorage.getItem("token") || "{}");
        if (token) config.headers["Authorization"] = `Bearer ${token.token}`
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// api.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//
//         const config = error?.config;
//
//         if(!['/auth/token'].includes(config.url) && [403, 401].includes(error?.response?.status)){
//             localStorage.clear()
//             const wl = window.location
//             wl.replace(`/login/?returnUrl=${wl.pathname}${wl.search}`)
//             return Promise.reject(error);
//         }
//
//         return Promise.reject(error);
//     }
// );

export default api;