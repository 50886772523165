import { CButton, CCol, CCollapse, CForm, CFormInput, CFormLabel, CFormSwitch, CFormTextarea, CInputGroup, CLoadingButton, CPlaceholder } from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../libs/api'
import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import { useEffect, useState } from 'react';
import useModalDelete from '../../hooks/modalDelete';
import { cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import { AesDecrypt, AesEncrypt } from '../../libs/aes';
import {GeneratePassword, GenericErrorHandler} from '../../libs/misc';

export function FormAddEdit({ edit, title }: { edit?: string, title: string }) {
    const [collapseVisible, setCollapseVisible] = useState(false)
    const [addToast] = useToast()
    const navigate = useNavigate();
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const FormSchema = z.object({
        name: z.string().nullable(),
        description: z.string().nullable(),
        username: z.string().nullable(),
        password: z.string().nullable(),
        is_active: z.boolean().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;
    const initial = {
        name: null,
        description: null,
        username: null,
        password: null,
        is_active: true,
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        control,
        reset,
        watch,
        getValues,
        setValue
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: (edit !== undefined
            ? initial
            : { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(initial)) }
        )
    })

    const instance = watch()

    useEffect(() => {
        if (edit === undefined) {
            localStorage.setItem(title, JSON.stringify(instance))
        }
    }, [edit, instance, title])

    useEffect(() => {
        if (edit !== undefined) {
            setIsRetrievingData(true)
            api.get(`/credentials/${edit}`).then(onfulfilled => {
                if (onfulfilled.data) {
                    reset({...onfulfilled.data, username: AesDecrypt(onfulfilled.data.username), password: AesDecrypt(onfulfilled.data.password)})
                }
                setIsRetrievingData(false)
            }).catch(reason => {
                GenericErrorHandler({reason, addToast})
            })
        } else {
            setIsRetrievingData(false)
        }
    }, [addToast, edit, reset])

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("name" | "description" | "username" | "password" | "is_active" | "root"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        const submitData = {...data, username : AesEncrypt(data.username|| ""), password : AesEncrypt(data.password|| "") }
        await api.post('/credentials', submitData).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
            reset(initial)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
        const submitData = {...data, username : AesEncrypt(data.username|| ""), password : AesEncrypt(data.password|| "") }
        await api.put(`/credentials/${edit}`, submitData).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const [UiModalDelete, UiButtonDelete] = useModalDelete({
        title: `Delete Piutang #${edit}`,
        path: `/credentials/${edit}`,
        confirmation: <>Saya yakin ingin menghapus <strong>Piutang #{edit}</strong></>
    })

    if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

    return (<>
        <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
            autoComplete='false'
        >
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Name</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Name'
                    invalid={errors.name != null}
                    feedbackInvalid={errors.name?.message as string}
                    {...register('name')}
                />
            </CCol>
            <CFormLabel htmlFor="description" className="col-md-2 col-form-label">Description</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormTextarea
                    placeholder='Description'
                    invalid={errors.description != null}
                    feedbackInvalid={errors.description?.message as string}
                    {...register('description')}
                />
            </CCol>
            <CFormLabel htmlFor="username" className="col-md-2 col-form-label">Username</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Username'
                    invalid={errors.username != null}
                    feedbackInvalid={errors.username?.message as string}
                    {...register('username') }
                />
            </CCol>
            <CFormLabel htmlFor="password" className="col-md-2 col-form-label">Password</CFormLabel>
            <CCol md={10} className='mb-3' >
                <CInputGroup>
                    <CFormInput
                        placeholder='Password'
                        invalid={errors.password != null}
                        feedbackInvalid={errors.password?.message as string}
                        {...register('password')}
                    />
                    <CButton onClick={() => {
                        setValue('password', GeneratePassword())
                    }}>Generate Password</CButton>
                </CInputGroup>
            </CCol>
            <CCol xs={12} className='text-center mb-3'>
                <span style={{ cursor: 'pointer' }} onClick={() => {
                    setCollapseVisible(x => !x)
                }}>
                    <CIcon icon={collapseVisible ? cisChevronBottom : cisChevronTop} />
                </span>
            </CCol>
            <CCol>
                <CCollapse className='row' visible={collapseVisible}>
                    <CCol md={10} className='offset-md-2 mb-3' >
                        <CFormSwitch
                            label="Aktif"
                            invalid={errors.is_active != null}
                            {...register('is_active')}
                        />
                    </CCol>
                </CCollapse>
            </CCol>

            <CCol md={10} className='mb-3 offset-md-2'>
                <CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
                <CLoadingButton color='secondary' className='me-2' onClick={() => {
                    reset(initial)
                }}>Clear</CLoadingButton>
                {edit && <UiButtonDelete />}
            </CCol>
        </CForm>
        {edit && <UiModalDelete />}
    </>)
}
