import {useEffect, useState} from "react";

export default function useEye() {
    const [eye, setEye] = useState<null | 'open' | 'close'>(() => {
        const ls = localStorage.getItem('eye')
        if(ls === null || !['open', 'close'].includes(ls))
            return null
        else
            return ls as ('open'|'close')
    })


    useEffect(() => {
        if(eye === "open" || eye === "close"){
            localStorage.setItem('eye', eye)
        }else{
            localStorage.removeItem('eye')
        }
    }, [eye]);

    return {eye, setEye}

}
