import { CCol, CCollapse, CForm, CFormLabel, CFormSwitch, CFormTextarea, CLoadingButton, CPlaceholder } from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../libs/api'
import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import {useContext, useEffect, useState} from 'react';
import useModalDelete from '../../hooks/modalDelete';
import moment from 'moment';
import InputImages from './componenet.input.images';
import { InputNominal } from './componenet.input.nominal';
import RiDatePicker from '../../components/inputs/DatePicker';
import CIcon from '@coreui/icons-react';
import { cisChevronBottom, cisChevronTop } from '@coreui/icons-pro'
import { IOption } from '../../types/inputs/select';
import { ITemplate } from '../../types/entities/template';
import RiSelect from "../../components/inputs/Select";
import {GenericErrorHandler} from "../../libs/misc";
import {AppContext} from "../../contexts/App";
export function FormAddEdit({ edit, title, template }: {
	edit?: string,
	title: string,
	template?: number
}) {
	const {theme} = useContext(AppContext)
	const [addToast] = useToast()
	const navigate = useNavigate();
	const [isRetrievingData, setIsRetrievingData] = useState(false)
	const FormSchema = z.object({
		transaksi: z.string().nullable(),
		nominal: z.string().nullable(),
		akun_debit_id: z.number().nullable(),
		akun_kredit_id: z.number().nullable(),
		date: z.string().nullable(),
		is_active: z.boolean().optional(),
		buku_pembantu_ids: z.array(z.number()),
		image_ids: z.array(z.number()),
	});
	// const [collapseVisible, setCollapseVisible] = useState(true)
	type FormSchemaType = z.infer<typeof FormSchema>;
	const init = {
		is_active: true,
		buku_pembantu_ids: [],
		image_ids: [],
		transaksi: null,
		nominal: null,
		akun_kredit_id: null,
		akun_debit_id: null,
		date: null,
	}
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
		setError,
		setValue,
		control,
		reset,
		watch
	} = useForm<FormSchemaType>({
		resolver: zodResolver(FormSchema),
		defaultValues: (edit !== undefined
			? init
			: { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(init)) }
		)
	})

	const instance = watch()
	const [collapseVisible, setCollapseVisible] = useState(false)

	useEffect(() => {
		if (edit === undefined) {
			localStorage.setItem(title, JSON.stringify(instance))
			if (instance.buku_pembantu_ids.length > 0 || instance.image_ids.length > 0 || instance.is_active === false) {
				setCollapseVisible(true)
			}
		}
	}, [edit, instance, title])

	useEffect(() => {
		if (edit !== undefined) {
			setIsRetrievingData(true)
			api.get(`/cashflows/${edit}`).then(onfulfilled => {
				if (onfulfilled.data) {
					if (onfulfilled.data.buku_pembantu_ids.length > 0 || onfulfilled.data.image_ids.length > 0 || onfulfilled.data.is_active === false) {
						setCollapseVisible(true)
					}
					reset(onfulfilled.data)
				}
				setIsRetrievingData(false)
			}).catch(reason => {
				GenericErrorHandler({reason, addToast})
            })
		} else {
			setIsRetrievingData(false)
		}
	}, [addToast, edit, reset, setValue])

	useEffect(() => {
		if (template !== undefined) {
			// setIsRetrievingData(true)
			api.get(`/templates/${template}`).then(onfulfilled => {
				if (onfulfilled.data) {
					const data : ITemplate = onfulfilled.data;
					reset({
						is_active: true,
						buku_pembantu_ids: data.temp_buku_pembantu_ids,
						image_ids: [],
						transaksi: data.temp_transaksi,
						nominal: null,
						akun_kredit_id: data.temp_akun_kredit_id,
						akun_debit_id: data.temp_akun_debit_id,
						date: null,
					})
					addToast({ message: `Template #${template} applied`, color: "success" })
				}
				
				// setIsRetrievingData(false)
			}).catch(() => {

			})
		} else {
			// setIsRetrievingData(false)
		}
	}, [addToast, reset, template])

	const additionalErrorHandler = (reason : any) => {
		for (let item in reason.response.data.errors) {
			setError(item as ("transaksi" | "nominal" | "akun_debit_id" | "akun_kredit_id" | "date" | "is_active" | "buku_pembantu_ids" | "image_ids" | "root"), { type: 'custom', message: reason.response.data.errors[item][0] })
		}
	}

	const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
		await api.post('/cashflows', data).then(() => {
			reset(init)
			addToast({ message: `${title} Successed`, color: "success" })
			navigate(-1)
		}).catch(reason => {
			GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
	}

	const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
		await api.put(`/cashflows/${edit}`, data).then(() => {
			addToast({ message: `${title} Successed`, color: "success" })
			navigate(-1)
		}).catch(reason => {
			GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
	}

	const [UiModalDelete, UiButtonDelete] = useModalDelete({
		title: `Delete Cash Flow #${edit}`,
		path: `/cashflows/${edit}`,
		confirmation: <>Saya yakin ingin menghapus <strong>Cash Flow #{edit}</strong></>
	})

	if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

	return (<>
		<CForm
			id='formSubmit'
			className="row"
			onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
			autoComplete='false'
		>
			<CFormLabel htmlFor="transaksi" className="col-md-2 col-form-label">Transaksi</CFormLabel>
			<CCol md={10} className='mb-3'>
				<Controller
					name="transaksi"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<CFormTextarea
							placeholder='Transaksi'
							invalid={error != null}
							feedbackInvalid={error?.message as string}
							value={value || ""}
							onChange={(e) => {
								onChange(e.target.value)
							}}
						/>
					)}
				/>

			</CCol>
			<CFormLabel htmlFor="akun_kredit" className="col-md-2 col-form-label">Akun Kredit</CFormLabel>
			<CCol md={10} className='mb-3' >
				<Controller
					name="akun_kredit_id"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<RiSelect
							type={"akun"}
							isSub={'false'}
							placeholder='Select Akun Kredit'
							feedbackInvalid={error?.message}
							invalid={error != null}
							value={value || undefined}
							onChange={(newValue) => {
								if (newValue) {
									const data = newValue as IOption
									onChange(data.value as number)
								} else {
									onChange(undefined)
								}
							}}
                            theme={theme}
						/>
					)}
				/>
			</CCol>
			<CFormLabel htmlFor="akun_debit" className="col-md-2 col-form-label">Akun Debit</CFormLabel>
			<CCol md={10} className='mb-3'>
				<Controller
					name="akun_debit_id"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<RiSelect
							type={"akun"}
							isSub={'false'}
							placeholder='Select Akun Debit'
							feedbackInvalid={error?.message}
							invalid={error != null}
							value={value || undefined}
							onChange={(newValue) => {
								if (newValue) {
									const data = newValue as IOption
									onChange(data.value as number)
								} else {
									onChange(undefined)
								}
							}}
							theme={theme}
						/>
					)}
				/>
			</CCol>
			<CFormLabel htmlFor="nominal" className="col-md-2 col-form-label">Nominal</CFormLabel>
			<CCol md={10} className='mb-3'>
				<Controller
					name="nominal"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<InputNominal
							id={'nominal'}
							placeholder='Nominal'
							invalid={error != null}
							feedbackInvalid={error?.message as string}
							value={value || undefined}
							onValueChange={(value) => {
								onChange(value || "")
							}}
						/>
					)}
				/>

			</CCol>
			<CFormLabel htmlFor="tanggal" className="col-md-2 col-form-label">Tanggal</CFormLabel>
			<CCol md={10} className='mb-3'>
				<Controller
					name="date"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<RiDatePicker
							id={'date'}
							placeholder={'date'}
							invalid={error != null}
							selected={new Date(moment(value || new Date()).format('YYYY-MM-DD')) || new Date()}
							feedbackInvalid={error?.message}
							onChange={(e) => {
								if (e != null) {
									onChange((moment(e).format('YYYY-MM-DD') || new Date()))
								} else {
									onChange(null)
								}

							}}
						/>
					)}
				/>
			</CCol>
			<CCol xs={12} className='text-center mb-3'>
				<span style={{ cursor: 'pointer' }} onClick={() => {
					setCollapseVisible(x => !x)
				}}>
					<CIcon icon={collapseVisible ? cisChevronBottom : cisChevronTop} />
				</span>
			</CCol>

			<CCol>
				<CCollapse className='row' visible={collapseVisible}>
					<CFormLabel htmlFor="buku_pembantu_ids" className="col-md-2 col-form-label">Buku Pembantu</CFormLabel>

					<CCol md={10} className='mb-3' >
						<Controller
							name="buku_pembantu_ids"
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<RiSelect
									type={"bukupembantu"}
									isMulti
									isSub='false'
									placeholder='Select Buku Pembantu'
									feedbackInvalid={error?.message}
									invalid={error != null}
									value={value || []}
									onChange={(newValue) => {
										if (newValue) {
											const data = newValue as IOption[]
											onChange(data.map(x => x.value as string))
										} else {
											onChange(undefined)
										}
									}}
                                    theme={theme}
								/>
							)}
						/>
					</CCol>
					<CFormLabel htmlFor="images" className="col-md-2 col-form-label">Images</CFormLabel>
					<CCol md={10} className='mb-3' >
						<Controller
							name="image_ids"
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputImages
									values={value || []}
									onChange={images => {
										onChange(images)
									}}
								/>
							)}
						/>

					</CCol>
					<CCol md={10} className='offset-md-2 mb-3' >
						<CFormSwitch
							label="Aktif"
							invalid={errors.is_active != null}
							{...register('is_active')}
						/>
						<div className="invalid-feedback">{errors.is_active?.message}asd</div>
					</CCol>
				</CCollapse>
			</CCol>
			{/* <CFormInput type='hidden' {...register('asset_pembelian_id')} />
			<CFormInput type='hidden' {...register('asset_pemeliharaan_id')} />
			<CFormInput type='hidden' {...register('asset_penjualan_id')} />
			<CFormInput type='hidden' {...register('hutang_pinjaman_id')} />
			<CFormInput type='hidden' {...register('hutang_pelunasan_id')} />
			<CFormInput type='hidden' {...register('hutang_bunga_id')} />
			<CFormInput type='hidden' {...register('piutang_pinjaman_id')} />
			<CFormInput type='hidden' {...register('piutang_pelunasan_id')} />
			<CFormInput type='hidden' {...register('piutang_profit_id')} /> */}
			<CCol md={10} className='mb-3 offset-md-2'>
				<CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
				<CLoadingButton color='secondary' className='me-2' onClick={() => {
					reset(init)
				}}>Clear</CLoadingButton>
				{edit && <UiButtonDelete />}
			</CCol>
		</CForm>
		{edit && <UiModalDelete />}
	</>)
}
