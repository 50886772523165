import { cisBook } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import { CListGroup, CListGroupItem, CPopover } from '@coreui/react-pro'
import { IBukuPembantu } from '../../types/entities/bukupembantu';

export default function PopOverBukuPembantus({ datas }: { datas: IBukuPembantu[] }) {

    if (datas.length === 0) return (<></>);
    return (<CPopover 
        // title="Buku Pembantus"
        content={<CListGroup flush>
            {datas.map((x, key) => <CListGroupItem key={key}>{x.full_name}</CListGroupItem>)}
        </CListGroup>}
        // placement="top"
        trigger={['click']}
    >
        <CIcon className='ms-2' icon={cisBook} />
    </CPopover>)
}

