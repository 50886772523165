import CryptoAes from 'crypto-js/aes';
import CtyptoUtf8 from 'crypto-js/enc-utf8'

export function AesEncrypt(plaintext: string) {
    const enc = CryptoAes.encrypt(plaintext, process.env.REACT_APP_PUBLIC_SECRET_KEY || "")
    return enc.toString()
}


export function AesDecrypt(ciphertext: string) {
    return CryptoAes.decrypt(ciphertext, process.env.REACT_APP_PUBLIC_SECRET_KEY || "").toString(CtyptoUtf8)
}