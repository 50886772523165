import { CButton, CCol, CLink, CPlaceholder, CRow, CSpinner } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useContext, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import { ICredential } from '../../types/entities/credential'
import { IPage } from '../../types/response/pagination'
import { AesDecrypt } from '../../libs/aes'
import { cisCopy } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";
import { AppContext } from '../../contexts/App'

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<ICredential>>()
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<ICredential>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    const { eye } = useContext(AppContext)

    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row, {
            id: 'name',
            cell: info => {
                const row = info.getValue()
                return (<span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}><CLink className='ms-2' style={{ cursor: 'pointer' }} onClick={() => {
                    navigate({ pathname: `edit/${row.id}` })
                }}>{row.name}</CLink></span>)
            },
            header: () => 'Name',
        }),
        columnHelper.accessor(row => row.description, {
            id: 'description',
            cell: info => info.getValue(),
            header: () => 'Description',
        }),
        columnHelper.accessor(row => row.username, {
            id: 'username',
            cell: info => {
                const data = AesDecrypt(info.getValue() || "");
                return(<CButton size='sm' className='mb-2' variant='outline' onClick={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(data)
                addToast({ message: "Username copied", color: 'info' })
            }}><CIcon className='me-2' icon={cisCopy} />{eye === 'close' ? '**********' : data}</CButton>)},
            header: () => 'Username',
        }),
        columnHelper.accessor(row => row.password, {
            id: 'password',
            cell: info => {
                const data = AesDecrypt(info.getValue() || "");
                return(<CButton size='sm' className='mb-2' variant='outline' onClick={(e) => {
                e.preventDefault()
                navigator.clipboard.writeText(data)
                addToast({ message: "Password copied", color: 'info' })
            }}><CIcon className='me-2' icon={cisCopy} />{eye === 'close' ? '**********' : data}</CButton>)},
            header: () => 'Password',
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',

            cell: info => {
                const row = info.getValue()
                return (<CRow>
                    <CCol xs={6}>
                        <span className='lead' style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>#{row.id}<CLink className='ms-2' style={{ cursor: 'pointer' }} onClick={() => {
                            navigate({ pathname: `edit/${row.id}` })
                        }}>{row.name}</CLink></span>
                    </CCol>
                    <CCol xs={12} className='my-2'>
                        <span className='lead'>{row.description}</span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='lead text-nowrap'><CButton size='sm' className='w-100 mb-2' variant='outline' onClick={(e) => {
                            e.preventDefault()
                            navigator.clipboard.writeText(AesDecrypt(row.username || ""))
                            addToast({ message: "Username copied", color: 'info' })
                        }}><CIcon className='me-2' icon={cisCopy} />{eye === 'close' ? '**********' : AesDecrypt(row.username || "")}</CButton></span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='lead text-nowrap'><CButton size='sm' className='w-100' variant='outline' onClick={(e) => {
                            e.preventDefault()
                            navigator.clipboard.writeText(AesDecrypt(row.password || ""))
                            addToast({ message: "Password copied", color: 'info' })
                        }}><CIcon className='me-2' icon={cisCopy} />{eye === 'close' ? '**********' : AesDecrypt(row.password || "")}</CButton></span>

                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',

        })
    ], [addToast, columnHelper, navigate, eye])


    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    };

    useEffect(() => {
        setloading(true)
        api.get(`/credentials?${searchParams.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <RiTable
                data={data?.data || []}
                columns={columns}
                responsive
            // footerVisible
            // onclickRow={(original: ICredential) => {
            //     navigate({ pathname: `edit/${original.id}` })
            // }}
            />
        </InfiniteScroll>

    </>)

}
