import { CButton, CCol, CForm, CFormLabel } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { IOption } from "../../types/inputs/select";
import moment from "moment";
import RiDatePicker from "../../components/inputs/DatePicker";
import RiSelect from "../../components/inputs/Select";
import {useContext} from "react";
import {AppContext} from "../../contexts/App";

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {theme} = useContext(AppContext)
    return (<CForm className='row gx-3 gy-2 align-items-center' >
        <CCol lg={3} xs={6}>
            <CFormLabel>Date After</CFormLabel>
            <RiDatePicker
                id={'date_after'}
                placeholder={'Date After'}
                selected={moment(searchParams.get("date_after"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_after") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment( moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_after')
                    else newsp.set('date_after', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={3} xs={6}>
            <CFormLabel>Date Before</CFormLabel>
            <RiDatePicker
                id={'date_before'}
                placeholder={'Date Before'}
                selected={moment(searchParams.get("date_before"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_before") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment( moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_before')
                    else newsp.set('date_before', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={6}>
            <RiSelect
                type={"bukupembantu"}
                isSub="true"
                placeholder='Buku Pembantu'
                label='Parent'
                size='sm'
                value={parseInt(searchParams.get('parent') || '')}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e) {
                        const data = e as IOption
                        newsp.set('parent', data.value.toString())
                    } else {
                        newsp.delete('parent')
                    }
                    setSearchParams(newsp)
                }}
                theme={theme}
            />
        </CCol>
      
        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting