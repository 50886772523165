
import { CCol, CPlaceholder, CRow } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useContext, useEffect, useMemo, useState } from 'react'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cisFolder, cisFile } from '@coreui/icons-pro'
import { IReportBukuPembantu } from '../../types/entities/report'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";
import { AppContext } from '../../contexts/App'

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IReportBukuPembantu[]>([])
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<IReportBukuPembantu>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    const { eye } = useContext(AppContext)

    const columns = useMemo(() => [
        columnHelper.accessor(row => row, {
            id: 'number',
            cell: info => {
                const value = info.getValue()
                return (<span style={{ textDecoration: !value.is_active ? 'line-through' : '' }}><CIcon icon={value.is_sub ? cisFolder : cisFile} className='me-2' />{`${value.number} ${value.name}`}</span>)
            },
            header: () => 'Buku Pembantu & Sub Buku Pembantu',
        }),
        columnHelper.accessor(row => row, {
            id: 'total',
            cell: info => {
                const row = info.getValue();

                if (row.is_sub) return (<></>)

                return (<span className='float-end' >
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(row.total)}
                </span>)
            },
            header: () => <span className='float-end'>Total (Rp,-)</span>,

        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',
            cell: info => {
                const row = info.getValue()
                return (<CRow>
                    <CCol xs={12}>
                        <span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                            <CIcon icon={row.is_sub ? cisFolder : cisFile} className='me-2' />
                            {`${row.number} ${row.name}`}
                        </span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='float-end' >
                            {row.is_sub ? '' : (eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(row.total))}
                        </span>
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',
        })

    ], [columnHelper, eye])


    useEffect(() => {
        setloading(true)
        let sp = createSearchParams(searchParams)
        if (!searchParams.get('parent')) sp.set('is_root', 'true')

        api.get(`/reports/bukupembantu?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])


    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <RiTable
            data={data}
            columns={columns}
            responsive
            onclickRow={(original: IReportBukuPembantu) => {
                if (original.is_sub) {
                    const sp = createSearchParams(searchParams)
                    sp.set("parent", original?.id?.toString() || "")
                    navigate({ search: sp.toString() })
                } else {
                    const sp = createSearchParams()
                    sp.set('buku_pembantu[]', original.id.toString())
                    if (searchParams.get('date_after')) sp.set('date_after', searchParams.get('date_after') || "")
                    if (searchParams.get('date_before')) sp.set('date_before', searchParams.get('date_before') || "")

                    navigate({ pathname: '/cashflow', search: sp.toString() })
                }
            }}
        />
    </>)

}
