
import { CCol, CLink, CPlaceholder, CRow, CSpinner } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { ICashflow } from '../../types/entities/cashflow'
import { IPage } from '../../types/response/pagination'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import PopOverBukuPembantus from './component.popover.bukupembantus'
import PopoverImages from './component.popover.images'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";
import { AppContext } from '../../contexts/App'

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<ICashflow>>()
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<ICashflow>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    const { eye } = useContext(AppContext)
    
    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row, {
            id: 'transaksi',
            cell: info =>{
                const data = info.getValue();
                return( <span>
                    <CLink style={{ cursor: 'pointer' }} onClick={() => {
                        navigate({ pathname: `edit/${data.id}` })
                    }}>
                        {data.transaksi}
                    </CLink>
                    <span className='text-nowrap'>
                        <PopOverBukuPembantus datas={data.buku_pembantus || []} />
                        <PopoverImages datas={data.images || []} />
                    </span>
                </span>)
            },
            header: () => 'Transaksi',
        }),
        columnHelper.accessor(row => row.akun_kredit?.full_name, {
            id: 'akun_kredit',
            cell: info => <span className='text-danger'>{info.getValue()}</span>,
            header: () => <span className='text-danger'>Akun Kredit</span>,
        }),
        columnHelper.accessor(row => row.akun_debit?.full_name, {
            id: 'akun_debit',
            cell: info => <span className='text-success'>{info.getValue()}</span>,
            header: () => <span className='text-success'>Akun Debit</span>,
        }),
        columnHelper.accessor(row => row.date, {
            id: 'date',
            cell: info => {
                return (<>
                    <span className='text-nowrap'>{moment(info.getValue()).format("DD MMM YYYY")}</span>
                    <span className='text-nowrap'><small className='ms-2 text-secondary'>{moment(info.getValue()).fromNow()}</small></span>
                </>)
            },
            header: () => 'Date',
        }),
        columnHelper.accessor(row => row, {
            id: 'nominal',
            cell: info => {
                const data = info.getValue()
                return (<span className='float-end' style={{ textDecoration: !data.is_active ? 'line-through' : '' }}>
                    {eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(data.nominal || 0)}
                </span>)
            },
            header: () => 'Nominal (Rp,-)',
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',
            cell: info => {
                const data = info.getValue()
                return (<>
                    <CRow>
                        <CCol xs={6}>
                            <span className='lead'>#{data.id}</span>
                            <span className='text-nowrap'>
                                <PopOverBukuPembantus datas={info.getValue().buku_pembantus || []} />
                                <PopoverImages datas={info.getValue().images || []} />
                            </span>
                        </CCol>
                        <CCol xs={6} className='text-end'>
                            <span className='text-nowrap'>{moment(data.date).format("DD MMM YYYY")}</span>
                            <span className='text-nowrap'><small className='ms-2 text-secondary'>{moment(data.date).fromNow()}</small></span>
                            {/* <PopOverDate date={moment(data.date).format("DD MMM YYYY")} text={moment(data.date).fromNow()} /> */}
                        </CCol>
                        <CCol xs={12} className='text-wrap mb-2 mt-2 lead'>
                            <CLink style={{ cursor: 'pointer' }} onClick={() => {
                                navigate({ pathname: `edit/${data.id}` })
                            }}>
                                {data.transaksi}
                            </CLink>
                        </CCol>
                        <CCol xs={12} className='text-danger'>
                            {data.akun_kredit?.full_name}
                        </CCol>
                        <CCol xs={12} className='text-success'>
                            {data.akun_debit?.full_name}
                        </CCol>
                        <CCol xs={12}>
                            <span className='display-4 float-end' style={{ textDecoration: !data.is_active ? 'line-through' : '' }}>
                            {eye === 'close' ? '**********' : <>Rp {new Intl.NumberFormat('id-ID').format(data.nominal || 0)}</>}
                            </span>
                        </CCol>
                    </CRow>
                </>)
            },
            header: () => 'Mobile',
        })
    ], [columnHelper, navigate, eye])

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData(current => ({ ...onfulfilled.data, data: [...(current?.data || []), ...onfulfilled.data.data] }))
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    };

    useEffect(() => {
        const sp = createSearchParams(searchParams)

        setloading(true)
        api.get(`/cashflows?${sp.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])

    const datatable = useMemo(() => data?.data || [], [data?.data]);

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <RiTable
                data={datatable}
                columns={columns}
                responsive
            // onclickRow={(original: ICashflow) => {
            //     navigate({ pathname: `edit/${original.id}` })
            // }}
            />
        </InfiniteScroll>
    </>)

}
