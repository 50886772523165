import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import { useContext, useEffect } from 'react'
import { AppContext } from '../../contexts/App'
import CIcon from '@coreui/icons-react'
import { cisFile, cisFolder } from '@coreui/icons-pro'
import { FormAddEdit } from './componenet.form.add.edit'

const Add = ({ isSub }: { isSub: boolean }) => {
    const { setMetaData } = useContext(AppContext)
    const title = `Add ${isSub ? 'Sub ' : ''}Akun`
    const icon = <CIcon icon={isSub? cisFolder : cisFile}  className='me-2' />

    useEffect(() => {
        setMetaData({ title })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{icon}{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <FormAddEdit isSub={isSub} title={title}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </>)
}

export default Add