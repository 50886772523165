import React, { useState, useEffect } from "react";
import { CPlaceholder } from "@coreui/react-pro";
import { IBukuPembantu } from "../../types/entities/bukupembantu";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import api from "../../libs/api";
import CIcon from "@coreui/icons-react";
import { cisChevronRight, cisFolderOpen } from "@coreui/icons-pro";
import useToast from "../../hooks/toast";
import {GenericErrorHandler} from "../../libs/misc";

export default function Path({ rootPath = "" }: { rootPath: string }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IBukuPembantu[]>([]);
    const [searchParams] = useSearchParams();
    const [newSearchParam, setNewSearchParam] = useState(createSearchParams())
    const [addToast] = useToast()

    useEffect(() => {
        setLoading(true)
        const parent_id = searchParams.get("parent")

        if (parent_id == null) {
            setData([])
            setLoading(false)
            return;
        }

        api.get(`/bukupembantus/${parent_id}/path`).then(x => {
            setData(x.data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })

        const newSP = createSearchParams(searchParams)
        newSP.delete("parent")
        setNewSearchParam(newSP)
    }, [addToast, searchParams])

    if (loading) return (<CPlaceholder animation="glow">
        <CPlaceholder xs={12} size="lg" />
    </CPlaceholder>)

    return (<>
        <React.Fragment>
            <CIcon icon={cisFolderOpen} className="me-1" />
            <Link
                to={{
                    pathname: `${rootPath}`,
                    search: newSearchParam.toString()
                }}
                style={{ textDecoration: 'none' }}
            >ROOT</Link>
        </React.Fragment>
        {data?.map((x, key) => {
            const sp = createSearchParams(newSearchParam)
            sp.set('parent', x.id?.toString() || "")
            return (<React.Fragment key={key}>
                <CIcon icon={cisChevronRight} className="ms-2 me-1" />
                {x.id?.toString() === (searchParams.get('parent') || "")
                    ? <span>{`${x.number} ${x.name}`}</span>
                    : <Link
                        to={{
                            pathname: `${rootPath}`,
                            search: sp.toString()
                        }}
                        style={{ textDecoration: 'none' }}
                    >
                        {`${x.number} ${x.name}`}
                    </Link>
                }
            </React.Fragment>)
        })}
    </>)
}
