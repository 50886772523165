import { CPlaceholder, CWidgetStatsA } from '@coreui/react-pro'
import { Link, createSearchParams, useNavigate } from 'react-router-dom'
import { useRetrieve } from '../../hooks/retrieve'
import moment from 'moment'
import { Line } from 'react-chartjs-2'
import type { ChartData, ChartOptions } from 'chart.js';
import CIcon from '@coreui/icons-react'
import { cilArrowBottom, cilArrowTop, cilPaperPlane, cilPlus } from '@coreui/icons-pro'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

interface IWidgetCashflow {
    data_count: number
    inactive_count: number
    yoy: number[]
}

export default function WidgetCashFlow() {
    const navigate = useNavigate()
    const [instance] = useRetrieve<IWidgetCashflow>({ path: "/dashboards/cashflow" })

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const current_date = new Date()
    //  .log(current_date)

    let lebels = [...Array(12)].map((x, key) => {
        let new_date = new Date(current_date)
        new_date.setMonth(current_date.getMonth() - key)
        return new_date
    }).reverse()

    const chart_data: ChartData<'line'> = {
        labels: lebels.map(x => moment(x).format('MMM yyyy')),
        datasets: [
            {
                backgroundColor: 'transparent',
                borderColor: 'rgba(255,255,255,.55)',
                pointBackgroundColor: 'rgba(255,255,255,.55)',
                data: instance?.yoy || [],
            }
        ],
    }

    const chart_options: ChartOptions<'line'> = {
        onHover: (event, chartElement) => {
            // event.native.target.style.cursor = 'pointer';
        },
        onClick: (event, element, aa) => {
            if (element.length > 0) {
                let first_day = lebels[element[0].index]
                first_day.setDate(1)
                let last_day = new Date(first_day.getFullYear(), first_day.getMonth() + 1, 0)
                let param = createSearchParams()
                param.set('date_after', moment(first_day).format('YYYY-MM-DD'))
                param.set('date_before', moment(last_day).format('YYYY-MM-DD'))
                navigate({
                    pathname: '/cashflow',
                    search: param.toString()
                })
            } else {
                navigate({
                    pathname: '/cashflow'
                })
            }
        },
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                    // drawBorder: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
        elements: {
            line: {
                borderWidth: 1,
            },
            point: {
                radius: 4,
                hitRadius: 10,
                hoverRadius: 4,
            },
        },
    }

    const chart =
        <Line
            className="mt-3 mx-3"
            height={40}
            data={chart_data}
            options={chart_options}
        />
    if (!instance) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<CWidgetStatsA
        className="mb-4"
        color="primary"
        value={
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => { navigate('/cashflow') }}
            >
                {instance && <>
                    {`${instance?.yoy[11]} of ${instance?.data_count} entries `}
                    <span className={`fs-6 fw-normal`}>
                        (
                        {(((instance?.yoy.at(11) || 0) - (instance?.yoy.at(10) || 0)) / (instance?.yoy.at(10) || 0) * 100).toLocaleString('id')}%
                        {instance?.yoy[11] !== instance?.yoy[10] && <CIcon icon={(instance?.yoy.at(11) || 0) > (instance?.yoy.at(10) || 0) ? cilArrowTop : cilArrowBottom} />}
                        )
                    </span>
                </>}
            </div>
        }
        chart={chart}
        title={
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => { navigate('/CashFlow') }}
            >
                <CIcon icon={cilPaperPlane} className={'me-2'} />{`Cash Flow, ${moment(new Date()).format('MMM yyyy')}`}
            </div>
        }
        action={
            <Link className="btn btn-sm btn-ghost-light" to='/CashFlow/Add'><CIcon icon={cilPlus} className='me-1' />Add Cash Flow</Link>
        }
    />)
}
