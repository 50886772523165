import {cilAccountLogout, cilContrast, cilEye, cilEyeSlash, cilMoon, cilSun} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CAvatar,
    CButton,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CNavItem
} from "@coreui/react-pro";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultUser from '../assets/images/layout/default_user.jpg'
import { AppContext } from "../contexts/App";
import useToast from "../hooks/toast";
import api from "../libs/api";

export default function HeaderEyeToggle() {
    const [showModalLogout, setshowModalLogout,] = useState(false)
    const { eye, setEye } = useContext(AppContext)
    const [addToast] = useToast();
    return (
        <>
            <CNavItem className="me-2">
                <CIcon style={{cursor: "pointer"}} size={'xxl'} icon={eye === 'open' ? cilEye : (eye === 'close' ? cilEyeSlash : cilEye)} className={'align-middle h-100'} onClick={() => {
                    setEye(eye === 'close' ? 'open': 'close')
                }}/>
            </CNavItem>
        </>
    )
}
