import { CCol, CCollapse, CForm, CFormInput, CFormLabel, CFormSwitch, CFormTextarea, CLoadingButton, CPlaceholder } from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../libs/api'
import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import {useContext, useEffect, useState} from 'react';
import useModalDelete from '../../hooks/modalDelete';
import { cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import { IOption } from '../../types/inputs/select';
import RiSelect from "../../components/inputs/Select";
import {GenericErrorHandler} from "../../libs/misc";
import {AppContext} from "../../contexts/App";

export function FormAddEdit({ edit, title }: { edit?: string, title: string }) {
    const {theme} = useContext(AppContext)
    const [isLocked, setIsLocked] = useState(false)
    const [collapseVisible, setCollapseVisible] = useState(false)
    const [addToast] = useToast()
    const navigate = useNavigate();
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const FormSchema = z.object({
        name: z.string().nullable(),
        temp_akun_debit_id: z.number().optional().nullable(),
        temp_akun_kredit_id: z.number().optional().nullable(),
        temp_buku_pembantu_ids: z.array(z.number()),
        temp_transaksi: z.string().nullable(),
        is_active: z.boolean().optional(),
        description: z.string().nullable(),
        message: z.void()
    });

    type FormSchemaType = z.infer<typeof FormSchema>;
    const initial = {
        name: null,
        temp_akun_debit_id: null,
        temp_akun_kredit_id: null,
        temp_buku_pembantu_ids: [],
        temp_transaksi: null,
        is_active: true,
        description: null,
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        control,
        reset,
        watch
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: (edit !== undefined
            ? initial
            : { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(initial)) }
        )
    })

    const instance = watch()

    useEffect(() => {
        if (edit === undefined) {
            localStorage.setItem(title, JSON.stringify(instance))
        }
    }, [edit, instance, title])

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("name" | "temp_akun_debit_id" | "temp_akun_kredit_id" | "temp_buku_pembantu_ids" | "message" | "temp_transaksi" | "is_active" | "description"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    useEffect(() => {
        if (edit !== undefined) {
            setIsRetrievingData(true)
            api.get(`/templates/${edit}`).then(onfulfilled => {
                if (onfulfilled.data) {
                    if (onfulfilled.data.temp_buku_pembantu_ids.length > 0 || onfulfilled.data.temp_akun_kredit_id || onfulfilled.data.temp_akun_kredit_id || onfulfilled.data.temp_transaksi) {
                        setCollapseVisible(true)
                    }
                    setIsLocked(onfulfilled.data.is_locked)
                    reset(onfulfilled.data)
                }
                setIsRetrievingData(false)
            }).catch(reason => {
                GenericErrorHandler({reason, addToast, additionalErrorHandler})
            })
        } else {
            setIsRetrievingData(false)
        }
    }, [addToast, edit, reset])


    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/templates', data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
            reset(initial)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }


    const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.put(`/templates/${edit}`, data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const [UiModalDelete, UiButtonDelete] = useModalDelete({
        title: `Delete Template #${edit}`,
        path: `/templates/${edit}`,
        confirmation: <>Saya yakin ingin menghapus <strong>Template #{edit}</strong></>
    })

    if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

    return (<>
        <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
            autoComplete='false'
        >
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Name</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormInput
                    placeholder='Name'
                    invalid={errors.name != null}
                    feedbackInvalid={errors.name?.message as string}
                    disabled={isLocked}
                    {...register('name')}
                />
            </CCol>
            <CFormLabel htmlFor="description" className="col-md-2 col-form-label">Description</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormTextarea
                    disabled={isLocked}
                    placeholder='Description'
                    invalid={errors.description != null}
                    feedbackInvalid={errors.description?.message as string}
                    {...register('description')}
                />

            </CCol>
            <CCol md={10} className='offset-md-2 mb-3' >
                <CFormSwitch
                    label="Aktif"
                    invalid={errors.is_active != null}
                    disabled={isLocked}
                    {...register('is_active')}
                />
            </CCol>
            <CCol xs={12} className='text-center mb-3'>
                <span style={{ cursor: 'pointer' }} onClick={() => {
                    setCollapseVisible(x => !x)
                }}>
                    <CIcon icon={collapseVisible ? cisChevronBottom : cisChevronTop} />
                </span>
            </CCol>
            <CCol>
                <CCollapse className='row' visible={collapseVisible}>
                    <CFormLabel htmlFor="temp_transaksi" className="col-md-2 col-form-label">Transaksi</CFormLabel>
                    <CCol md={10} className='mb-3'>
                        <CFormTextarea
                            disabled={isLocked}
                            placeholder='Transaksi'
                            invalid={errors.temp_transaksi != null}
                            feedbackInvalid={errors.temp_transaksi?.message as string}
                            {...register('temp_transaksi')}
                        />
                    </CCol>
                    <CFormLabel htmlFor="temp_akun_kredit_id" className="col-md-2 col-form-label">Akun Kredit</CFormLabel>
                    <CCol md={10} className='mb-3' >
                        <Controller
                            name="temp_akun_kredit_id"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RiSelect
                                    type={"akun"}
                                    isDisabled={isLocked}
                                    isSub={'false'}
                                    placeholder='Select Akun Kredit'
                                    feedbackInvalid={error?.message}
                                    invalid={error != null}
                                    value={value || undefined}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            const data = newValue as IOption
                                            onChange(data.value as number)
                                        } else {
                                            onChange(undefined)
                                        }
                                    }}
                                    theme={theme}
                                />
                            )}
                        />
                    </CCol>
                    <CFormLabel htmlFor="temp_akun_debit_id" className="col-md-2 col-form-label">Akun Debit</CFormLabel>
                    <CCol md={10} className='mb-3'>
                        <Controller
                            name="temp_akun_debit_id"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RiSelect
                                    type={"akun"}
                                    isDisabled={isLocked}
                                    isSub={'false'}
                                    placeholder='Select Akun Debit'
                                    feedbackInvalid={error?.message}
                                    invalid={error != null}
                                    value={value || undefined}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            const data = newValue as IOption
                                            onChange(data.value as number)
                                        } else {
                                            onChange(undefined)
                                        }
                                    }}
                                    theme={theme}
                                />
                            )}
                        />
                    </CCol>
                    <CFormLabel htmlFor="temp_buku_pembantu_ids" className="col-md-2 col-form-label">Buku Pembantu</CFormLabel>
                    <CCol md={10} className='mb-3' >
                        <Controller
                            name="temp_buku_pembantu_ids"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RiSelect
                                    type={"bukupembantu"}
                                    isDisabled={isLocked}
                                    isMulti
                                    isSub='false'
                                    placeholder='Select Buku Pembantu'
                                    feedbackInvalid={error?.message}
                                    invalid={error != null}
                                    value={value || []}
                                    onChange={(newValue) => {

                                        if (newValue) {
                                            const data = newValue as IOption[]
                                            onChange(data.map(x => x.value as string))
                                        } else {
                                            onChange(undefined)
                                        }
                                    }}
                                    theme={theme}
                                />
                            )}
                        />
                    </CCol>
                </CCollapse>
            </CCol>
            {!isLocked && <CCol md={10} className='mb-3 offset-md-2'>
                <CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
                <CLoadingButton color='secondary' className='me-2' onClick={() => {
                    reset(initial)
                }}>Clear</CLoadingButton>
                {edit && <UiButtonDelete />}
            </CCol>}
        </CForm>
        {edit && <UiModalDelete />}
    </>)
}
