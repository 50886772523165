
import {CImage, CPlaceholder, CSpinner} from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../libs/api'
import { IImage } from '../../types/entities/image'
import { IPage } from '../../types/response/pagination'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<IImage>>()
    const [loading, setloading] = useState(true)
    const navigate = useNavigate();
    const [addToast] = useToast()

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    };

    useEffect(() => {
        setloading(true)
        api.get(`/images?${searchParams.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
            className="row"
        >
            {data?.data.map((d,key) => <div key={key} className="col-xl-1 col-lg-1 col-md-2 col-6">
                <CImage className=" img-thumbnail" style={{cursor: "pointer"}} src={d.thumb_url} alt={d.caption} onClick={() => {
                    navigate(`edit/${d.id}`)
                }}/>
                {/*<PopOverImage data={d}/>*/}
            </div>)}
        </InfiniteScroll>
        
    </>)

}
