import CurrencyInput from 'react-currency-input-field'
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps'

export default function RiCurrency({
    id = "",
    placeholder = "",
    value = undefined,
    className = undefined,
    invalid = undefined,
    onValueChange = undefined,
    name = undefined,
    size = "md"
}: {
    id: string,
    placeholder?: string,
    value?: string | undefined,
    className?: string | undefined,
    onValueChange?: ((value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => void) | undefined
    invalid?: boolean | undefined,
    name?: string | undefined,
    size? : 'md'| 'sm'
}) {
    
    return (<CurrencyInput
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        className={[className, 'form-control', `form-control-${size}`, (invalid ? 'is-invalid' : '')].join(" ")}
        prefix="Rp "
        onValueChange={onValueChange}
    />)
}
