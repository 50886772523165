import Select, {ActionMeta, CSSObjectWithLabel, GroupBase, MultiValue, OptionProps, SingleValue} from 'react-select'
import {IOption} from '../../types/inputs/select';
import api from '../../libs/api';
import {useEffect, useState} from 'react';
import {CFormFeedback, CFormLabel} from '@coreui/react-pro';
import {IAkun} from '../../types/entities/akun';
import useToast from '../../hooks/toast';
import {ITemplate} from "../../types/entities/template";
import {IBukuPembantu} from "../../types/entities/bukupembantu";
import {GenericErrorHandler} from "../../libs/misc";

export default function RiSelect({
                                     placeholder = null,
                                     value = undefined,
                                     className = undefined,
                                     isMulti = undefined,
                                     onChange = undefined,
                                     invalid = undefined,
                                     name = undefined,
                                     size = 'md',
                                     defaultInputValue,
                                     isSub = undefined,
                                     label,
                                     feedbackInvalid,
                                     fixedValue,
                                     isDisabled,
                                     type,
                                     theme = 'light'
                                 }: {
    placeholder?: string | null,
    value?: number | number[] | undefined,
    className?: string | undefined,
    isMulti?: boolean | undefined,
    onChange?: ((newValue: MultiValue<IOption> | SingleValue<IOption>, actionMeta: ActionMeta<IOption>) => void) | undefined,
    invalid?: boolean | undefined,
    name?: string | undefined,
    size?: 'md' | 'sm',
    defaultInputValue?: string,
    isSub?: 'true' | 'false',
    label?: string,
    feedbackInvalid?: string,
    fixedValue?: number[],
    isDisabled?: boolean,
    type: 'akun' | 'bukupembantu' | 'template',
    theme?: 'light' | 'dark',
}) {
    const [options, setOptions] = useState<IOption[]>([])
    const [loading, setLoading] = useState(false)
    const [addToast] = useToast()

    function populateAkun() {
        setLoading(true)
        const qs = new URLSearchParams()
        qs.set('list', 'true')
        qs.set('ordering', 'number')
        if (isSub !== undefined) qs.set('is_sub', isSub)

        api.get(`/akuns?${qs.toString()}`).then(x => {
            const data: IAkun[] = x.data
            setOptions(data.map(x => {
                return {
                    label: x.full_name,
                    value: x.id as number,
                    disabled: !x.is_active,
                    color: x.is_sub ? 'rgba(229, 83, 83)' : undefined,
                    is_fixed: fixedValue?.includes(x.id as number)
                } as IOption
            }))
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }

    function populateBukuPembantu() {
        setLoading(true)
        const qs = new URLSearchParams()
        qs.set('list', 'true')
        qs.set('ordering', 'number')
        if (isSub !== undefined) qs.set('is_sub', isSub)

        api.get(`/bukupembantus?${qs.toString()}`).then(x => {
            const data: IBukuPembantu[] = x.data
            setOptions(data.map(x => {
                return {
                    label: x.full_name,
                    value: x.id as number,
                    disabled: !x.is_active,
                    color: x.is_sub ? 'rgba(229, 83, 83)' : undefined,
                    is_fixed: fixedValue?.includes(x.id as number)
                } as IOption
            }))
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }

    function populateTemplate() {
        setLoading(true)
        const qs = new URLSearchParams()
        qs.set('list', 'true')
        qs.set('ordering', 'number')
        if (isSub !== undefined) qs.set('is_sub', isSub)

        api.get(`/templates?${qs.toString()}`).then(x => {
            const data: ITemplate[] = x.data
            setOptions(data.map(x => {
                return {
                    label: `${x.id}. ${x.name}`,
                    value: x.id as number,
                    disabled: !x.is_active,
                    color: undefined,
                    is_fixed: fixedValue?.includes(x.id as number)
                } as IOption
            }))
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }

    useEffect(() => {
        switch (type) {
            case 'akun':
                populateAkun()
                break;
            case 'bukupembantu':
                populateBukuPembantu()
                break;
            case 'template':
                populateTemplate()
                break;
        }
    }, [addToast, fixedValue, isSub])

    return (<>
        {label && <CFormLabel>{label}</CFormLabel>}
        <Select
            isDisabled={isDisabled}
            placeholder={placeholder}
            value={isMulti ? (value ? options.filter(x => (value as number[] || []).includes(x.value as number)) : []) : (value ? options.find(x => x.value === value as number) : null)}
            options={options}
            isOptionDisabled={option => option.disabled === true}
            name={name}
            className={[className, (invalid && 'is-invalid')].join(' ')}
            isMulti={isMulti}
            onChange={onChange}
            isClearable={options.filter(x => x.is_fixed).length === 0}
            isLoading={loading}
            defaultInputValue={defaultInputValue}
            styles={{
                control: (provided: any, state: { isFocused: boolean }) => ({
                    ...provided,
                    background: isDisabled ? 'var(--cui-secondary-bg)' : undefined,
                    backgroundColor: undefined,
                    borderRadius: 'var(--cui-border-radius)',
                    minHeight: size === 'sm' ? '31px' : undefined,
                    borderColor: invalid ? '#dc3545' : (state.isFocused ? '#998fed' : 'var(--cui-border-color)'),
                    boxShadow: invalid ? (state.isFocused ? '0 0 0 0.25rem rgb(220 53 69 / 25%)' : 'none') : (state.isFocused ? '0 0 0 0.25rem rgba(50,31,219,.25)' : 'none'),
                    "&:hover": {
                        borderColor: invalid ? '#dc3545' : (state.isFocused ? '#998fed' : '#d8dbe0'),
                        boxShadow: invalid ? (state.isFocused ? '0 0 0 0.25rem rgb(220 53 69 / 25%)' : 'none') : (state.isFocused ? '0 0 0 0.25rem rgba(50,31,219,.25)' : 'none'),
                    },
                    fontSize: size === 'sm' ? '89%' : undefined,
                }),
                menuList: (provided, state) => ({
                    ...provided,
                }),
                clearIndicator: (provided, state) => ({
                    ...provided,
                    maxWidth: size === 'sm' ? '25px' : undefined,
                    maxHeight: size === 'sm' ? '25px' : undefined,
                    padding: size === 'sm' ? '4px' : '8px',
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    maxWidth: size === 'sm' ? '25px' : undefined,
                    maxHeight: size === 'sm' ? '25px' : undefined,
                    padding: size === 'sm' ? '4px' : '8px',
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                }),
                option: (base: CSSObjectWithLabel, props: OptionProps<IOption, boolean, GroupBase<IOption>>) => {
                    return {
                        ...base,
                        color: props.data.color || "black"
                    }
                },
                valueContainer: (provided, state) => ({
                    ...provided,
                    padding: size === 'sm' ? '0px 8px' : '2px 8px',
                }),
                input: (base, state) => ({
                    ...base,
                    color: undefined
                }),
                placeholder: (base, state) => ({
                    ...base,
                    color: undefined
                }),
                multiValueRemove: (base, state) => {
                    return state.data.is_fixed ? {...base, display: 'none'} : base;
                },
                // placeholder: (base) => ({ ...base, color: undefined }),
                singleValue(base, props) {
                    return {...base,
                        color: theme === 'dark' ? 'white' : 'dark'
                    };
                },

            }}
        />
        <CFormFeedback invalid>{feedbackInvalid}</CFormFeedback>
    </>)
}

