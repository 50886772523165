import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (<CForm className='row gx-3 gy-2 align-items-center' >

        <CCol lg={8}>
            <CFormInput
                value={searchParams.get("name") || ''}
                size='sm'
                placeholder="Name"
                aria-label="Name"
                label={"Name"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('name')
                    else newsp.set('name', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Status</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('is_active') || ''}
                options={[
                    { label: 'Semua', value: "" },
                    { label: 'Aktif', value: 'true' },
                    { label: 'Tidak Aktif', value: 'false' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "Semua") newsp.delete('is_active')
                    else newsp.set('is_active', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || '-id'}
                options={[
                    { label: '# (desc)', value: '-id' },
                    { label: '# (asc)', value: "id" },
                    { label: 'Name (desc)', value: '-name' },
                    { label: 'Name (asc)', value: 'name' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "-id") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>

        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting