import {useEffect, useState} from "react";

export default function useDarkMode() {
    const [mode, setMode] = useState<null | 'light' | 'dark'>(() => {
        const ls = localStorage.getItem('mode')
        if(ls === null || !['dark', 'light'].includes(ls))
            return null
        else
            return ls as ('light'|'dark')
    })
    const [theme, setTheme] = useState<'light'|'dark'>((): 'light' | 'dark' => {
        const ls = localStorage.getItem('mode')

        if(ls === null || !['dark', 'light'].includes(ls))
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        else
            return mode as 'light' | 'dark' ;
    })


    useEffect(() => {
        if(mode === "light"){
            localStorage.setItem('mode', 'light')
            setTheme('light')
        }else if(mode === "dark"){
            localStorage.setItem('mode', 'dark')
            setTheme('dark')
        }else{
            localStorage.removeItem('mode')
            setTheme(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
        }
    }, [mode]);

    return {mode , setMode, theme}

}
