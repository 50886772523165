
import AkunIndex from "../pages/akun"
import AkunAdd from "../pages/akun/add"
import AkunEdit from "../pages/akun/edit"

import BukuPembantuIndex from "../pages/bukupembantu"
import BukuPembantuAdd from "../pages/bukupembantu/add"
import BukuPembantuEdit from "../pages/bukupembantu/edit"

import CashFlowIndex from "../pages/cashflow"
import CashFlowAdd from "../pages/cashflow/add"
import CashFlowEdit from "../pages/cashflow/edit"

import HutangIndex from "../pages/hutang"
import HutangAdd from "../pages/hutang/add"
import HutangEdit from "../pages/hutang/edit"

import PiutangIndex from "../pages/piutang"
import PiutangAdd from "../pages/piutang/add"
import PiutangEdit from "../pages/piutang/edit"

import InventoryIndex from "../pages/inventory"
import InventoryAdd from "../pages/inventory/add"
import InventoryEdit from "../pages/inventory/edit"

import BebanIndex from "../pages/beban"
import BebanAdd from "../pages/beban/add"
import BebanEdit from "../pages/beban/edit"

import CredentialIndex from "../pages/credential"
import CredentialAdd from "../pages/credential/add"
import CredentialEdit from "../pages/credential/edit"

import NeracaIndex from "../pages/report_neraca"
import GraphIndex from "../pages/report_graph"
import ReportBukuPembantuIndex from "../pages/report_bukupembantu"
import DashboardIndex from "../pages/dashboard"

import { IRoute } from "../types/layout"

import TemplateIndex from "../pages/template"
import TemplateAdd from "../pages/template/add"
import TemplateEdit from "../pages/template/edit"

import ImageIndex from "../pages/image"
import ImageEdit from "../pages/image/edit"

import BackupIndex from "../pages/backup"

const routes: IRoute[] = [
    { path: '/', name: 'Dashboard', element: <DashboardIndex /> },

    // { path: '/editprofile', name: 'Edit Profile', element: <ChangeProfileIndex /> },
    // { path: '/changepassword', name: 'Change Password', element: <ChangePasswordIndex /> },
    // { path: '/changepicture', name: 'Change Profile Picture', element: <ChangePictureIndex /> },

    { path: '/akun', name: 'Data Akun', element: <AkunIndex /> },
    { path: '/akun/add', name: 'Add', element: <AkunAdd isSub={false} /> },
    { path: '/akun/addsub', name: 'Add Sub', element: <AkunAdd isSub={true} /> },
    { path: '/akun/edit/:id', name: 'Edit', element: <AkunEdit isSub={false} /> },
    { path: '/akun/editsub/:id', name: 'Edit', element: <AkunEdit isSub={true} /> },

    { path: '/bukupembantu', name: 'Data Buku Pembantu', element: <BukuPembantuIndex /> },
    { path: '/bukupembantu/add', name: 'Add', element: <BukuPembantuAdd isSub={false} /> },
    { path: '/bukupembantu/addsub', name: 'Add Sub', element: <BukuPembantuAdd isSub={true} /> },
    { path: '/bukupembantu/edit/:id', name: 'Edit', element: <BukuPembantuEdit isSub={false} /> },
    { path: '/bukupembantu/editsub/:id', name: 'Edit', element: <BukuPembantuEdit isSub={true} /> },

    { path: '/cashflow', name: 'Cash Flow', element: <CashFlowIndex /> },
    { path: '/cashflow/add', name: 'Add', element: <CashFlowAdd /> },
    { path: '/cashflow/edit/:id', name: 'Edit', element: <CashFlowEdit /> },

    { path: '/hutang', name: 'Hutang', element: <HutangIndex /> },
    { path: '/hutang/add', name: 'Add', element: <HutangAdd /> },
    { path: '/hutang/edit/:id', name: 'Edit', element: <HutangEdit /> },


    { path: '/piutang', name: 'Piutang', element: <PiutangIndex /> },
    { path: '/piutang/add', name: 'Add', element: <PiutangAdd /> },
    { path: '/piutang/edit/:id', name: 'Edit', element: <PiutangEdit /> },

    { path: '/inventory', name: 'Inventory', element: <InventoryIndex /> },
    { path: '/inventory/add', name: 'Add', element: <InventoryAdd /> },
    { path: '/inventory/edit/:id', name: 'Edit', element: <InventoryEdit /> },

    { path: '/beban', name: 'Beban', element: <BebanIndex /> },
    { path: '/beban/add', name: 'Add', element: <BebanAdd /> },
    { path: '/beban/edit/:id', name: 'Edit', element: <BebanEdit /> },

    { path: '/credential', name: 'Credential', element: <CredentialIndex /> },
    { path: '/credential/add', name: 'Add', element: <CredentialAdd /> },
    { path: '/credential/edit/:id', name: 'Edit', element: <CredentialEdit /> },

    { path: '/reportneraca', name: 'Neraca', element: <NeracaIndex /> },
    { path: '/reportgraph', name: 'Graph', element: <GraphIndex /> },
    { path: '/reportbukupembantu', name: 'Report Buku Pembantu', element: <ReportBukuPembantuIndex /> },

    // { path: '/image', name: 'Image', element: <ImageIndex /> },
    // { path: '/image/detail/:id', name: 'Detail', element: <ImageDetail /> },

    { path: '/template', name: 'Data Template', element: <TemplateIndex /> },
    { path: '/template/add', name: 'Add', element: <TemplateAdd /> },
    { path: '/template/edit/:id', name: 'Edit', element: <TemplateEdit /> },

    { path: '/image', name: 'Image', element: <ImageIndex /> },
    { path: '/image/edit/:id', name: 'Edit', element: <ImageEdit /> },

    { path: '/backup', name: 'Backup', element: <BackupIndex /> },
]

export default routes
