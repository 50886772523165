import { CCol, CForm, CFormInput, CFormLabel, CFormSwitch, CFormTextarea, CLoadingButton, CPlaceholder } from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../libs/api'
import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import {useContext, useEffect, useState} from 'react';
import { IOption } from '../../types/inputs/select';
import useModalDelete from '../../hooks/modalDelete';
import RiSelect from "../../components/inputs/Select";
import {GenericErrorHandler} from "../../libs/misc";
import {AppContext} from "../../contexts/App";

export function FormAddEdit({ edit, isSub, title }: { edit?: string, isSub: boolean, title: string }) {
    const [isLocked, setIsLocked] = useState(false)
    const [addToast] = useToast()
    const {theme}= useContext(AppContext)
    const navigate = useNavigate();
    const [isRetrievingData, setIsRetrievingData] = useState(true)
    const FormSchema = z.object({
        name: z.string().nullable(),
        description: z.string().nullable(),
        parent_id: z.number().nullable(),
        is_active: z.boolean().optional(),
        is_sub: z.boolean().nullable(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const init = { is_sub: isSub, is_active: true, name: null, desctiption: null, parent_id: null }

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        control,
        reset,
        watch
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: (edit === undefined
            ? init
            : { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(init)) }
        )
    })

    const instance = watch()

    useEffect(() => {
        if (edit === undefined) {
            localStorage.setItem(title, JSON.stringify(instance))
        }
    }, [edit, instance, title])

    useEffect(() => {
        if (edit !== undefined) {
            setIsRetrievingData(true)
            api.get(`/akuns/${edit}`).then(onfulfilled => {
                if (onfulfilled.data) {
                    setIsLocked(onfulfilled.data.is_locked)
                    reset(onfulfilled.data)
                }
                setIsRetrievingData(false)
            }).catch(reason => {
                GenericErrorHandler({reason,addToast})
            })
        } else {
            setIsRetrievingData(false)
        }
    }, [addToast, edit, reset])

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("name" | "description" | "parent_id" | "is_active" | "is_sub"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/akuns', data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.put(`/akuns/${edit}`, data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const [UiModalDelete, UiButtonDelete] = useModalDelete({
        title: `Delete ${getValues("is_sub") ? 'Sub ' : ''}Akun #${edit}`,
        path: `/akuns/${edit}`,
        confirmation: <>Saya yakin ingin menghapus <strong>{getValues("is_sub") ? 'Sub ' : ''}Akun #{edit}</strong></>
    })


    if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

    return (<>
        <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
            autoComplete='false'
        >
            <CFormLabel htmlFor="name" className="col-md-2 col-form-label">Name</CFormLabel>
            <CCol md={10} className='mb-3' >
                <CFormInput
                    type="text"
                    placeholder='Name'
                    invalid={errors.name != null}
                    feedbackInvalid={errors.name?.message as string}
                    disabled={isLocked}
                    {...register("name")}
                />
            </CCol>
            <CFormLabel htmlFor="description" className="col-md-2 col-form-label">Description</CFormLabel>
            <CCol md={10} className='mb-3' >
                <CFormTextarea
                    placeholder='Description'
                    invalid={errors.description != null}
                    feedbackInvalid={errors.description?.message as string}
                    disabled={isLocked}
                    {...register("description")}
                />
            </CCol>
            <CFormLabel htmlFor="parent" className="col-md-2 col-form-label">Parent</CFormLabel>
            <CCol md={10} className='mb-3'>
                <Controller
                    name="parent_id"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <RiSelect
                            type={"akun"}
                            isDisabled={isLocked}
                            isSub='true'
                            placeholder='Select Parent'
                            feedbackInvalid={error?.message}
                            invalid={error != null}
                            value={value || undefined}
                            onChange={(e) => {
                                if (e) {
                                    const data = e as IOption
                                    onChange(data.value as number)
                                } else {
                                    onChange(undefined)
                                }
                            }}
                            theme={theme}
                        />
                    )}
                />
            </CCol>
            <CCol md={10} className='offset-md-2 mb-3' >
                <CFormSwitch
                    label="Aktif"
                    invalid={errors.is_active != null}
                    disabled={isLocked}
                    {...register('is_active')}
                />
                <div className="invalid-feedback">{errors.is_active?.message}</div>
            </CCol>

            <CFormInput
                type='hidden'
                {...register('is_sub')}
            />
            {!isLocked && <CCol md={10} className='mb-3 offset-md-2'>
                <CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
                <CLoadingButton color='secondary' className='me-2' onClick={() => {
                    reset(init)
                }}>Clear</CLoadingButton>
                {edit && <UiButtonDelete />}
            </CCol>}

        </CForm>
        {edit && <UiModalDelete />}
    </>)
}