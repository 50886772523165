import {
    CCol,
    CCollapse,
    CForm,
    CFormLabel,
    CFormSwitch,
    CFormTextarea,
    CImage,
    CLoadingButton,
    CPlaceholder
} from '@coreui/react-pro'
import { z } from 'zod';
import { useForm, SubmitHandler } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import api from '../../libs/api'
import { useNavigate } from 'react-router-dom'
import useToast from '../../hooks/toast'
import { useEffect, useState } from 'react';
import useModalDelete from '../../hooks/modalDelete';
import { cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {GenericErrorHandler} from "../../libs/misc";

export function FormAddEdit({ edit, title }: { edit?: string, title: string }) {
    const [collapseVisible, setCollapseVisible] = useState(false)
    const [addToast] = useToast()
    const navigate = useNavigate();
    const [isRetrievingData, setIsRetrievingData] = useState(false)
    const FormSchema = z.object({
        image: z.string().nullable().readonly(),
        image_url: z.string().nullable().readonly(),
        caption: z.string().nullable(),
        is_active: z.boolean().optional(),
        message: z.void()
    });

    type FormSchemaType = z.infer<typeof FormSchema>;
    const initial = {
        image: null,
        image_url: null,
        caption: null,
        is_active: true,
    };
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        reset,
        watch,
        getValues
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: (edit !== undefined
            ? initial
            : { ...JSON.parse(localStorage.getItem(title) || JSON.stringify(initial)) }
        )
    })

    const instance = watch()

    useEffect(() => {
        if (edit === undefined) {
            localStorage.setItem(title, JSON.stringify(instance))
        }
    }, [edit, instance, title])

    useEffect(() => {
        if (edit !== undefined) {
            setIsRetrievingData(true)
            api.get(`/images/${edit}`).then(onfulfilled => {
                if (onfulfilled.data) {
                    if (onfulfilled.data.is_active === false) {
						setCollapseVisible(true)
					}
                    reset(onfulfilled.data)
                }
                setIsRetrievingData(false)
            }).catch(reason => {
                GenericErrorHandler({reason, addToast})
            })
        } else {
            setIsRetrievingData(false)
        }
    }, [addToast, edit, reset])

    const additionalErrorHandler = (reason : any) => {
        for (let item in reason.response.data.errors) {
            setError(item as ("caption" | "image" | "message" | "image_url" | "is_active" | "root"), { type: 'custom', message: reason.response.data.errors[item][0] })
        }
    }

    const onCreate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post('/images', data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
            reset(initial)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const onUpdate: SubmitHandler<FormSchemaType> = async (data) => {
        await api.put(`/images/${edit}`, data).then(() => {
            addToast({ message: `${title} Successed`, color: "success" })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast, additionalErrorHandler})
        });
    }

    const [UiModalDelete, UiButtonDelete] = useModalDelete({
        title: `Delete Image #${edit}`,
        path: `/images/${edit}`,
        confirmation: <>Saya yakin ingin menghapus <strong>Image #{edit}</strong></>
    })

    if (isRetrievingData) return (<CPlaceholder xs={12} size="lg" />)

    return (<>
        <CForm
            id='formSubmit'
            className="row"
            onSubmit={handleSubmit(edit !== undefined ? onUpdate : onCreate)}
            autoComplete='false'
        >
            <CFormLabel htmlFor="image" className="col-md-2 col-form-label">Image</CFormLabel>
            <CCol md={10}>
                <CImage className={"img-thumbnail mb-4"} src={getValues("image_url") || ""}/>
            </CCol>
            <CFormLabel htmlFor="caption" className="col-md-2 col-form-label">Caption</CFormLabel>
            <CCol md={10} className='mb-3'>
                <CFormTextarea
                    placeholder='Caption'
                    invalid={errors.caption != null}
                    feedbackInvalid={errors.caption?.message as string}
                    {...register('caption')}
                />
            </CCol>
            <CCol xs={12} className='text-center mb-3'>
                <span style={{ cursor: 'pointer' }} onClick={() => {
                    setCollapseVisible(x => !x)
                }}>
                    <CIcon icon={collapseVisible ? cisChevronBottom : cisChevronTop} />
                </span>
            </CCol>
            <CCol>
                <CCollapse className='row' visible={collapseVisible}>
                    <CCol md={10} className='offset-md-2 mb-3' >
                        <CFormSwitch
                            label="Aktif"
                            invalid={errors.is_active != null}
                            {...register('is_active')}
                        />
                    </CCol>
                </CCollapse>
            </CCol>

            <CCol md={10} className='mb-3 offset-md-2'>
                <CLoadingButton form='formSubmit' loading={isSubmitting} type="submit" className='me-2'>{edit ? 'Update' : 'Create'}</CLoadingButton>
                {/*<CLoadingButton color='secondary' className='me-2' onClick={() => {*/}
                {/*    reset(initial)*/}
                {/*}}>Clear</CLoadingButton>*/}
                {/*{edit && <UiButtonDelete />}*/}
            </CCol>
        </CForm>
        {edit && <UiModalDelete />}
    </>)
}
