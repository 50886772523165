
import { CCol, CPlaceholder, CRow, CSpinner } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import { IPiutang } from '../../types/entities/piutang'
import { IPage } from '../../types/response/pagination'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<IPiutang>>()
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<IPiutang>()
    const navigate = useNavigate();
    const [addToast] = useToast()
    
    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row, {
            id: 'piutang',
            cell: info => {
                const row = info.getValue()
                return (<span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                    {row.piutang}
                </span>)
            },
            header: () => 'Piutang',
        }),
        columnHelper.accessor(row => row.date_pinjam, {
            id: 'date_pinjam',
            cell: info => {
                return (<>
                    <span className='text-nowrap'>{moment(info.getValue()).format("DD MMM YYYY")}</span>
                    <span className='text-nowrap'><small className='ms-2 text-secondary'>{moment(info.getValue()).fromNow()}</small></span>
                </>)
            },
            header: () => 'Date Pinjam',
        }),
        columnHelper.accessor(row => row.date_kembali, {
            id: 'date_kembali',
            cell: info => {
                return (<>
                    <span className='text-nowrap'>{moment(info.getValue()).format("DD MMM YYYY")}</span>
                    <span className='text-nowrap'><small className='ms-2 text-secondary'>{moment(info.getValue()).fromNow()}</small></span>
                </>)
            },
            header: () => 'Date Kembali',
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',

            cell: info => {
                const row = info.getValue()
                return (<CRow>
                    <CCol xs={6} style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                        <span className='lead'>#{row.id} {row.piutang}</span>
                    </CCol>
                    <CCol xs={12}>
                        <span className='text-nowrap'>{moment(row.date_pinjam).format("DD MMM YYYY")} - {moment(row.date_kembali).format("DD MMM YYYY")}</span>
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',

        })
    ], [columnHelper])


    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    };

    useEffect(() => {
        setloading(true)
        api.get(`/piutangs?${searchParams.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <RiTable
                data={data?.data || []}
                columns={columns}
                responsive
                // footerVisible
                onclickRow={(original: IPiutang) => {
                    navigate({ pathname: `edit/${original.id}` })
                }}
            />
        </InfiniteScroll>

    </>)

}
