import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRetrieve } from '../../hooks/retrieve'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { CPlaceholder, CWidgetStatsD } from '@coreui/react-pro';
import { Chart } from 'react-chartjs-2';
import CIcon from '@coreui/icons-react';
import { cilNotes } from '@coreui/icons-pro';

interface IWidgetAkun {
    non_sub_count : number
    sub_count : number
    inactive_count : number
}

export default function WidgetAkun() {
    const navigate = useNavigate()
    const [instance] = useRetrieve<IWidgetAkun>({ path: "/dashboards/akun" })

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Legend,
        Title,
        Tooltip
      );
      
      if (!instance) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )

    return (<CWidgetStatsD
        style={{cursor : 'pointer'}}
        onClick= {() => {
            navigate({
                pathname : '/akun'
            })
        }}
        className="mb-3"
        icon={ <><CIcon icon={cilNotes} className="my-3 text-white" height={40} /> <span className="text-white ms-3">DATA AKUN</span></>}
        color={'primary'}
        values={[
            { title: 'Akun', value: instance?.non_sub_count },
            { title: 'Sub Akun', value: instance?.sub_count },
        ]}
        chart={
            <Chart
                type="bar" 
                className="position-absolute w-100 h-100"
                options={{
                    maintainAspectRatio: false,
                    plugins:{
                        legend:{
                            display:false
                        },
                    },
                    scales: {
                        x: {
                          display: false,
                        },
                        y: {
                          display: false,
                        },
                      },
                }} 

                data={{
                    labels : ['Akun', 'Sub Akun'],
                    datasets: [
                        {
                            data: [instance?.non_sub_count, instance?.sub_count],
                            borderColor: 'rgba(255,255,255,.55)',
                            backgroundColor: 'rgba(255,255,255,.1)',
                        },
                    ],
                }} 
            />
        }
    />)
}
