import React from 'react'
import { Link, matchPath, useLocation, useSearchParams } from 'react-router-dom';
import routes from '../data/routes';
import { CBreadcrumb, CBreadcrumbItem, CContainer, CHeaderDivider } from '@coreui/react-pro';

const Breadcrumb = () => {
    const [searchParams] = useSearchParams();
    const currentLocation = useLocation().pathname

    const getRouteName = (pathname: string, routes: any[]) => {
        const currentRoute = routes.find((route) =>
            matchPath(route, pathname)
        )
        return currentRoute ? currentRoute.name : null
    }

    const getBreadcrumbs = (location: string) => {
        const breadcrumbs = []
        breadcrumbs.push({
            path: {
                pathname: '/'
            },
            name: 'Home',
            active: false,
        })
        location.split('/').reduce((prev, curr, index, array) => {
            const currentPathname = `${prev}/${curr}`
            const routername = getRouteName(currentPathname, routes)
            if (routername != null) {
                breadcrumbs.push({
                    path: {
                        pathname: currentPathname,
                        search: searchParams.toString()
                    },
                    name: getRouteName(currentPathname, routes),
                    active: index + 1 === array.length ? true : false,
                })
            }
            return currentPathname
        })
        return breadcrumbs;
    }

    const breadcrumbs = getBreadcrumbs(currentLocation)

    return (<>
        <CHeaderDivider />
        <CContainer fluid>
            <CBreadcrumb className="m-0 ms-2">
                {breadcrumbs.map((breadcrumb, index) => {
                    return (
                        <CBreadcrumbItem
                            {...(breadcrumb.active ? { active: true } : {})}
                            key={index}
                        >
                            {breadcrumb.active ?
                                <>{breadcrumb.name}</> :
                                <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
                            }
                        </CBreadcrumbItem>
                    )
                })}
            </CBreadcrumb>
        </CContainer>
    </>)
}

export default React.memo(Breadcrumb)
