import { CButton, CCol, CFormFeedback, CImage, CModal, CModalBody, CRow, CSpinner } from '@coreui/react-pro'
import {useContext, useEffect, useState} from 'react'
import Dropzone from 'react-dropzone'
import api from '../../libs/api'
import useToast from '../../hooks/toast'
import { IImage } from '../../types/entities/image'
import CIcon from '@coreui/icons-react'
import { cidDelete } from '@coreui/icons-pro'
import {AppContext} from "../../contexts/App";

export default function InputImages({
    values,
    feedbackInvalid,
    invalid = false,
    onChange
}: {
    values: number[],
    feedbackInvalid?: string,
    invalid?: boolean,
    onChange: (values: number[]) => void
}) {
    const [loading, setLoading] = useState(false)
    const [addToast] = useToast()
    const { mode } = useContext(AppContext)
    const onDrop = async (acceptedFiles: any[]) => {

        if (acceptedFiles.length === 0) {
            addToast({
                color: "danger",
                message: `Invalid format`
            })
            return;
        }

        setLoading(true)
        Promise.all(acceptedFiles.map(file => {
            let datasubmit = new FormData();
            datasubmit.append('image', file)
            return api.post('/images', datasubmit).then(async onfulfilled => {
                return onfulfilled.data.id
            }).catch(() => {
                return null
            })
        })).then(x => {
            onChange([...values, ...x.filter(x => x != null)])
            addToast({
                color: "success",
                message: `${x.filter(x => x != null).length} of ${acceptedFiles.length} Image(s) uploaded`
            })
        }).catch(x => {
            addToast({
                color: "danger",
                message: "Error upload image (s)"
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const onClear = () => {
        onChange([])
    }

    return (<>
        <div className={`border rounded${(values || []).length > 0 ? "-top" : ""} p-1`}>
            <Dropzone disabled={loading} onDrop={onDrop} accept={{ 'image/*': [] }}>
                {({ getRootProps, getInputProps }) => (
                    <div className=''>

                        <CButton className="m-0 me-2" color={mode === 'dark' ? 'light' : (mode === 'light' ? 'dark' : ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'light' : 'dark'))} variant="outline" size="sm" {...getRootProps()}>
                            <input accept="image/*" {...getInputProps()} />
                            <span className='me-2'>{loading ? <><CSpinner size='sm' className='me-2' />Uploading...</> : "Click or drop file(s) here"}</span>
                        </CButton>
                        {(values || []).length > 0 &&
                            <CButton className="m-1" variant="outline" color="danger" size="sm" onClick={onClear}>Clear {values.length} image(s)</CButton>
                        }
                    </div>
                )}
            </Dropzone>


        </div>
        {(values || []).length > 0 && <div className="border rounded-bottom p-2">
            <CRow xs={{ cols: 'auto' }}>
                {(values || []).map((x, key) => <SingleImage key={key} id={x} onDelete={(id) => {
                    onChange(values.filter(x => x !== id))
                }} />)}
            </CRow>
        </div>}
        <CFormFeedback invalid>{feedbackInvalid}</CFormFeedback>
    </>)
}

function SingleImage({
    id,
    onDelete
}: {
    id: number,
    onDelete: (id: number) => void
}) {
    const [data, setData] = useState<IImage>({})
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        setData({})
        api.get(`/images/${id}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setLoading(false)
        }).catch(() => { })
    }, [id])

    return (
        <>
            {loading
                ? <CSpinner className='m-3' />
                : <>
                    <CCol className="position-relative">
                        <CIcon className="text-danger" size='xl' icon={cidDelete} style={{ cursor: "pointer", position: "absolute", top: "2px", right: "13px" }} onClick={() => { onDelete(id) }} />
                        <CImage style={{ cursor: "pointer" }} onClick={() => { setVisible(sm => !sm) }} className="m-1" thumbnail src={data.thumb_url} />
                    </CCol>

                    <CModal size="lg" backdrop={false} visible={visible} onClose={() => setVisible(false)}>
                        <CModalBody><CImage align="center" fluid src={data.image_url} /></CModalBody>
                    </CModal>
                </>
            }
        </>

    )
}
