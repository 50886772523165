import React, { ReactElement, useEffect, useState } from 'react';

import { AppContext } from './contexts/App';
import { CContainer, CToaster } from '@coreui/react-pro';
import Header from './layout/header';
import Sidebar from './layout/sidebar';
import { Outlet } from 'react-router-dom';
import useToggle from './hooks/toggle';
import api from './libs/api';
import { IUser } from './types/entities/user';
import { IMetaData } from './types/layout';
import useToast from './hooks/toast';
import {ModalLogin} from "./layout/modal.login";
import {ModelRefresh} from "./layout/modal.refresh";
import {GenericErrorHandler} from "./libs/misc";
import useDarkMode from "./hooks/darkMode";
import useEye from './hooks/eye';

function App() {
    const { on: visible, toggle: toggleVisible } = useToggle(true)
    const { on: unfoldable, toggle: toggleUnfoldable } = useToggle(false)

    const [toast, setToast] = useState<ReactElement | undefined>()
    const [user, setUser] = useState<IUser>()
    const [visibleModalLogin, setVisibleModalLogin] = useState(false)
    const [visibleModalRefresh, setVisibleModalRefresh] = useState(false)

    const [metaData, setMetaData] = useState<IMetaData>({})
    const [addToast] = useToast()

    const {mode, setMode, theme } = useDarkMode();
    const {eye, setEye } = useEye();

    useEffect(() => {
        document.body.setAttribute('data-coreui-theme', theme);
    }, [theme]);

    useEffect(() => {
        api.get('/auth/me').then(onfulfilled => {
            setUser(onfulfilled.data)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }, [addToast])

    useEffect(() => {
        const interval = setInterval(async () => {
            setVisibleModalLogin(localStorage.getItem('token') === null);
        }, 500);

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        document.title = `${metaData.title ? `${metaData.title} | ` : ''}${process.env.REACT_APP_PUBLIC_SITE_NAME}`
    }, [metaData.title])



    return (<>
        <AppContext.Provider value={{
            sidebar: {
                visible: visible,
                unfoldable: unfoldable,
                toggleVisible: toggleVisible,
                toggleUnfoldable: toggleUnfoldable,
            },
            user: user,
            setUser: setUser,
            setToast: setToast,
            setMetaData: setMetaData,
            visibleModalLogin: visibleModalLogin,
            setVisibleModalLogin: setVisibleModalLogin,
            visibleModalRefresh: visibleModalRefresh,
            setVisibleModalRefresh: setVisibleModalRefresh,
            mode: mode,
            setMode: setMode,
            eye: eye,
            setEye: setEye,
            theme: theme
        }}>
            <React.StrictMode>
                <Sidebar />
                <div className="wrapper d-flex flex-column min-vh-100">
                    <Header />
                    <div className="body flex-grow-1 px-3">
                        <CContainer xl className='pb-4'>
                            <Outlet />
                        </CContainer>
                    </div>
                </div>
            </React.StrictMode>
            <ModalLogin/>
            <ModelRefresh/>
        </AppContext.Provider>

        <CToaster push={toast} placement="top-end" />

    </>)
}

export default App;
