import { createContext } from "react";
import { IAppContext } from "../types/context";

const AppContext = createContext<IAppContext>({
    sidebar: {
        visible: true,
        unfoldable: true,
        toggleVisible: () => {},
        toggleUnfoldable: () => {},
    },
    setToast : () => {},
    user : {},
    setUser : (x) => {},
    setMetaData : (x) => {},
    visibleModalLogin : false,
    setVisibleModalLogin : (x) => {},
    visibleModalRefresh: false,
    setVisibleModalRefresh:  (x) => {},
    mode : null,
    setMode: (x: ('dark' | 'light' | null) ) => {},
    eye : null,
    setEye: (x: ('open' | 'close' | null) ) => {},
    theme: 'light'

});

export { AppContext };
