import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"
import moment from "moment"
import RiDatePicker from "../../components/inputs/DatePicker";

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (<CForm className='row gx-3 gy-2 align-items-center'>
        <CCol lg={4}>
            <CFormInput
                value={searchParams.get("piutang") || ''}
                size='sm'
                placeholder="Piutang"
                aria-label="Piutang"
                label={"Piutang"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('piutang')
                    else newsp.set('piutang', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Tanggal Pinjam After</CFormLabel>
            <RiDatePicker
                id={'date_pinjam_after'}
                placeholder={'Date Pinjam After'}
                selected={moment(searchParams.get("date_pinjam_after"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_pinjam_after") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_pinjam_after')
                    else newsp.set('date_pinjam_after', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Date Pinjam Before</CFormLabel>
            <RiDatePicker
                id={'date_pinjam_before'}
                placeholder={'Tanggal Pinjam Before'}
                selected={moment(searchParams.get("date_pinjam_before"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_pinjam_before") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_pinjam_before')
                    else newsp.set('date_pinjam_before', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Date Kembali After</CFormLabel>
            <RiDatePicker
                id={'date_kembali_after'}
                placeholder={'Tanggal Pinjam After'}
                selected={moment(searchParams.get("date_kembali_after"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_kembali_after") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_kembali_after')
                    else newsp.set('date_kembali_after', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Date Kembali Before</CFormLabel>
            <RiDatePicker
                id={'date_kembali_before'}
                placeholder={'Tanggal Pinjam Before'}
                selected={moment(searchParams.get("date_kembali_before"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_kembali_before") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_kembali_before')
                    else newsp.set('date_kembali_before', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Status</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('is_active') || ''}
                options={[
                    { label: 'Semua', value: "" },
                    { label: 'Aktif', value: 'true' },
                    { label: 'Tidak Aktif', value: 'false' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "Semua") newsp.delete('is_active')
                    else newsp.set('is_active', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || '-id'}
                options={[
                    { label: '# (desc)', value: '-id' },
                    { label: '# (asc)', value: "id" },
                    { label: 'Piutang (desc)', value: '-piutang' },
                    { label: 'Piutang (asc)', value: 'piutang' },
                    { label: 'Date Pinjam (desc)', value: '-date_pinjam' },
                    { label: 'Date Pinjam (asc)', value: 'date_pinjam' },
                    { label: 'Date Kembali (desc)', value: '-date_kembali' },
                    { label: 'Date Kembali (asc)', value: 'date_kembali' },
                    { label: 'Total Pinjaman (desc)', value: '-total_pinjaman' },
                    { label: 'Total Pinjaman (asc)', value: 'total_pinjaman' },
                    { label: 'Total Pelunasan (desc)', value: '-total_pelunasan' },
                    { label: 'Total Pelunasan (asc)', value: 'total_pelunasan' },
                    { label: 'Total Profit (desc)', value: '-total_profit' },
                    { label: 'Total Profit (asc)', value: 'total_profit' },

                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "-id") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting