
import { CCol, CPlaceholder, CRow, CSpinner } from '@coreui/react-pro'
import { createColumnHelper } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../libs/api'
import RiTable from '../../components/Table'
import { IPiutang } from '../../types/entities/piutang'
import { IPage } from '../../types/response/pagination'
import { IBeban } from '../../types/entities/beban'
import useToast from '../../hooks/toast'
import {GenericErrorHandler} from "../../libs/misc";

export default function Table() {
    const [searchParams] = useSearchParams();
    const [data, setData] = useState<IPage<IBeban>>()
    const [loading, setloading] = useState(true)
    const columnHelper = createColumnHelper<IBeban>()
    const navigate = useNavigate()
    const [addToast] = useToast()

    const columns = useMemo(() => [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => '#',
        }),
        columnHelper.accessor(row => row, {
            id: 'beban',
            cell: info => {
                const row = info.getValue()
                return (<span style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                    {row.beban}
                </span>)
            },
            header: () => 'Name',
        }),
        columnHelper.accessor(row => row.keterangan, {
            id: 'keterangan',
            cell: info => info.getValue(),
            header: () => 'Keterangan',
        }),
        columnHelper.accessor(row => row, {
            id: 'mobile',

            cell: info => {
                const row = info.getValue()
                return (<CRow>
                    <CCol xs={12} >
                        <span className='lead' style={{ textDecoration: !row.is_active ? 'line-through' : '' }}>
                            #{row.id} {row.beban}
                        </span>
                    </CCol>
                    <CCol xs={12}  >
                        {row.keterangan}
                    </CCol>
                </CRow>)
            },
            header: () => 'Mobile',

        })
    ], [columnHelper])

    const fetchMoreData = () => {
        if (data?.next_page_url) api.get(data.next_page_url).then(onfulfilled => {
            setData({ ...onfulfilled.data, data: [...data.data, ...onfulfilled.data.data] })
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    };

    useEffect(() => {
        setloading(true)
        api.get(`/bebans?${searchParams.toString()}`).then(onfulfilled => {
            setData(onfulfilled.data)
            setloading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast, searchParams])

    if (loading) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    else return (<>
        <InfiniteScroll
            dataLength={data?.data.length || 0}
            next={fetchMoreData}
            hasMore={data?.next_page_url != null}
            loader={<div className='text-center m-2'>
                <CSpinner variant="grow" size='sm' /> <b>Memuat data</b>
            </div>}
        >
            <RiTable
                data={data?.data || []}
                columns={columns}
                responsive
                // footerVisible
                onclickRow={(original: IPiutang) => {
                    navigate({ pathname: `edit/${original.id}` })
                }}
            />
        </InfiniteScroll>

    </>)

}
