import {CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect} from "@coreui/react-pro"
import {createSearchParams, useSearchParams} from "react-router-dom"
import moment from "moment"
import RiDatePicker from "../../components/inputs/DatePicker";
import {IOption} from "../../types/inputs/select"
import {InputNominal} from "./componenet.input.nominal";
import RiSelect from "../../components/inputs/Select";
import {useContext} from "react";
import {AppContext} from "../../contexts/App";

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {theme}= useContext(AppContext)
    return (<CForm className='row gx-3 gy-2 align-items-center'>
        <CCol lg={4}>
            <CFormInput
                value={searchParams.get("transaksi") || ''}
                size='sm'
                placeholder="Transaksi"
                aria-label="Transaksi"
                label={"Transaksi"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('transaksi')
                    else newsp.set('transaksi', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Date After</CFormLabel>
            <RiDatePicker
                id={'date_after'}
                placeholder={'Date After'}
                selected={moment(searchParams.get("date_after"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_after") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_after')
                    else newsp.set('date_after', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Date Before</CFormLabel>
            <RiDatePicker
                id={'date_before'}
                placeholder={'Date Before'}
                selected={moment(searchParams.get("date_before"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_before") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_before')
                    else newsp.set('date_before', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Nominal Min</CFormLabel>
            <InputNominal
                id={'nominal_min'}
                name='nominal_min'
                placeholder='Nominal Min'
                value={searchParams.get('nominal_min') || ''}
                onValueChange={(value, name) => {
                    const newsp = createSearchParams(searchParams)
                    if (value === undefined || value === "") newsp.delete('nominal_min')
                    else newsp.set('nominal_min', value || "")
                    setSearchParams(newsp)
                }}
                size="sm"
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Nominal Max</CFormLabel>
            <InputNominal
                id={'nominal_max'}
                name='nominal_max'
                placeholder='Nominal Max'
                value={searchParams.get('nominal_max') || ''}
                onValueChange={(value, name) => {
                    const newsp = createSearchParams(searchParams)
                    if (value === undefined || value === "") newsp.delete('nominal_max')
                    else newsp.set('nominal_max', value || "")
                    setSearchParams(newsp)
                }}
                size="sm"
            />
        </CCol>
        <CCol lg={4}>
            <RiSelect
                type={"akun"}
                placeholder='Akun'
                label='Akun'
                size='sm'
                value={parseInt(searchParams.get('akun') || "")}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e) {
                        const data = e as IOption
                        newsp.set('akun', data.value.toString())
                    } else {
                        newsp.delete('akun')
                    }
                    setSearchParams(newsp)
                }}
                theme={theme}
            />
        </CCol>
        <CCol lg={4}>
            <RiSelect
                type={"bukupembantu"}
                placeholder='Buku Pembantu'
                label='Buku Pembantu'
                size='sm'
                isMulti
                value={searchParams.getAll('buku_pembantu[]').map(x => parseInt(x || "")) || []}
                onChange={(newValue) => {
                    const newsp = createSearchParams(searchParams)
                    if (newValue) {
                        newsp.delete('buku_pembantu[]')
                        const data = newValue as IOption[]
                        data.forEach(element => {
                            newsp.append('buku_pembantu[]', element.value.toString())
                        });
                    } else {
                        newsp.delete('buku_pembantu[]')
                    }
                    setSearchParams(newsp)
                }}
                theme={theme}
            />
        </CCol>
        <CCol lg={4} xs={6}>
            <CFormLabel>Status</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('is_active') || ''}
                options={[
                    {label: 'Semua', value: ""},
                    {label: 'Aktif', value: 'true'},
                    {label: 'Tidak Aktif', value: 'false'},
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "Semua") newsp.delete('is_active')
                    else newsp.set('is_active', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>

        <CCol lg={4} xs={6}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || '-id'}
                options={[
                    {label: '# (asc)', value: "id"},
                    {label: '# (desc)', value: '-id'},
                    {label: 'Transaksi (asc)', value: 'transaksi'},
                    {label: 'Transaksi (desc)', value: '-transaksi'},
                    {label: 'Akun Kredit (asc)', value: 'akun_kredit_id'},
                    {label: 'Akun Kredit (desc)', value: '-akun_kredit_id'},
                    {label: 'Akun Debit (asc)', value: 'akun_debit_id'},
                    {label: 'Akun Debit (desc)', value: '-akun_debit_id'},
                    {label: 'Tanggal (asc)', value: 'date'},
                    {label: 'Tanggal (desc)', value: '-date'},
                    {label: 'Nominal (asc)', value: 'nominal'},
                    {label: 'Nominal (desc)', value: '-nominal'},
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "-id") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting