
import { CTable, CTableBody, CTableDataCell, CTableFoot, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react-pro"
import { getCoreRowModel, useReactTable, flexRender } from "@tanstack/react-table"

export default function RiTable<A>({
    data,
    columns,
    responsive = false,
    footerVisible = false,
    onclickRow
}: {
    data: unknown[]
    columns: any[]
    responsive?: boolean
    footerVisible?: boolean
    onclickRow?: (original:A) => void
}) {

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    if (data.length === 0) return (<div className="text-center"><em>No data to show</em></div>)
    return (
        <CTable className="text-nowrap" hover >
            <CTableHead >
                {table.getHeaderGroups().map(headerGroup => {
                    return (
                        <CTableRow key={headerGroup.id} className={[(responsive ? 'd-none d-md-table-row' : '')].join(" ")}>
                            {headerGroup.headers.map(header => {
                                let className: string[] = []
                                if (header.column.columnDef.id?.includes('nominal')) className.push('text-end')
                                if (responsive) {
                                    if (header.column.columnDef.id === 'mobile') className = [...className, ...["d-md-none", "text-wrap"]]
                                }
                                return (
                                    <CTableHeaderCell key={header.id} className={className.join(" ")}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </CTableHeaderCell>)
                            })}
                        </CTableRow>
                    )
                })}
            </CTableHead>
            <CTableBody>
                {table.getRowModel().rows.map(row => (
                    <CTableRow key={row.id} onClick={() => onclickRow?.((row.original as A))} style={{ cursor: (onclickRow ? "pointer" : undefined) }}>
                        {row.getVisibleCells().map(cell => {
                            let className: string[] = ["align-middle"]
                            if (cell.column.columnDef.id !== 'actions') className = [...className, ...["text-wrap"]]
                            if (responsive) {
                                if (cell.column.columnDef.id === 'mobile') className = [...className, ...["d-md-none"]]
                                else className = [...className, ...["d-none", "d-md-table-cell"]]
                            }

                            return (<CTableDataCell key={cell.id} className={className.join(" ")}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </CTableDataCell>)
                        })}
                    </CTableRow>
                ))}
                {table.getRowModel().rows.length === 0 && <CTableRow>
                    <div className="text-center"><em>No data to show</em></div>
                </CTableRow>}
            </CTableBody>
            {footerVisible && <CTableFoot>
                {table.getFooterGroups().map(footerGroup => (
                    <CTableRow key={footerGroup.id} >
                        {footerGroup.headers.map(footer => {
                            let className: string[] = []
                            if (footer.column.columnDef.id !== 'actions') className = [...className, ...["text-wrap"]]
                            if (responsive) {
                                if (footer.column.columnDef.id === 'mobile') className = [...className, ...["d-md-none"]]
                                else className = [...className, ...["d-none", "d-md-table-cell"]]
                            }
                            return (
                                <CTableHeaderCell key={footer.id} className={className.join(" ")}>
                                    {footer.isPlaceholder
                                        ? null
                                        : flexRender(
                                            footer.column.columnDef.footer,
                                            footer.getContext()
                                        )}
                                </CTableHeaderCell>)
                        })}
                    </CTableRow>
                ))}
            </CTableFoot>}
        </CTable>
    )
}
