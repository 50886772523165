import React, {Component, useContext, useEffect} from 'react';
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCol, CForm, CFormInput,
    CImage, CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CRow, CSpinner
} from "@coreui/react-pro";
import api from "../libs/api";
import {AppContext} from "../contexts/App";
import {useNavigate, useSearchParams} from "react-router-dom";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import demo from "../assets/images/layout/demo.svg";
import logoNegative from "../assets/images/layout/logo-negative.png";
import useToast from "../hooks/toast";
import {IToken} from "../types/token";
import {GenericErrorHandler} from "../libs/misc";

export function ModelRefresh(){

    const { visibleModalRefresh,  } = useContext(AppContext)
    const [addToast] = useToast()

    return(<CModal portal backdrop={'static'} size='sm' alignment='center' visible={visibleModalRefresh}>
        <CModalBody >
            <div className={'text-center'}>
                <span><CSpinner size={"sm"}/> Refresh token...</span>
            </div>
        </CModalBody>
    </CModal>)
}