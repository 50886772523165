import { CButton, CCard, CCardBody, CCol, CCollapse, CRow } from '@coreui/react-pro'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../contexts/App'
import { useSearchParams } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cilArrowTop, cisChevronBottom, cisChevronTop } from '@coreui/icons-pro'
import ScrollToTop from 'react-scroll-to-top'
import Filter from './componenet.filter.sorting'
import Graph from './componenet.graph'

export default function Index() {
    const { setMetaData, theme } = useContext(AppContext)
    const [searchParams] = useSearchParams();
    const [collapseFilterSorting, setCollapseFilterSorting] = useState(searchParams.has('date_after') ||
        searchParams.has('date_before') || searchParams.has('akun') || searchParams.has('rule')
    )

    useEffect(() => {
        setMetaData({
            title: "Graph"
        })
    }, [setMetaData])

    return (<>
        <CRow>
            <CCol md={12}>
                <CButton active={collapseFilterSorting} color={'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    setCollapseFilterSorting(x => !x)
                }}> Filter <CIcon className='ms-1' icon={collapseFilterSorting ? cisChevronBottom : cisChevronTop} /></CButton>
            </CCol>
        </CRow>
        <CCollapse visible={collapseFilterSorting}>
            <CRow className='mb-3'>
                <CCol>
                    <CCard>
                        <CCardBody>
                            <Filter />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCollapse>
        <CRow>
            <CCol>
                <CCard>
                    <CCardBody className='overflow-auto'>
                        <Graph />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ScrollToTop smooth component={<CIcon icon={cilArrowTop} />} className={`rounded-circle bg-${theme}`} />
    </>)
}
