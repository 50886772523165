import React, { useContext, useEffect, useState } from 'react'
import { CToast, CToastBody, CToastClose } from '@coreui/react-pro';
import { AppContext } from '../contexts/App';


export default function useToast() {
    const { setToast} = useContext(AppContext);
    const [toast, addToast] = useState<{
        message: string,
        color: "success" | "danger" | "info" | "warning" | "dark",
    }>({
        message: "",
        color: "dark",
    })

    useEffect(() => {
        if (toast.message)
            setToast(<CToast
                autohide={true}
                visible={true}
                color={toast.color}
                className={[`align-items-center`, `text-white`].join(' ')}
            >
                <div className="d-flex">
                    <CToastBody>{toast?.message}</CToastBody>
                    <CToastClose className="me-2 m-auto" white />
                </div>
            </CToast>)

    }, [setToast, toast])

    return [addToast]
}
