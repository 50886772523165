import { useContext, useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CCollapse, CRow } from '@coreui/react-pro';
import { useSearchParams } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import { AppContext } from '../../contexts/App';
import CIcon from '@coreui/icons-react';
import { cilArrowTop, cisChevronBottom, cisChevronTop } from '@coreui/icons-pro';
import FilterSorting from './componenet.filter.sorting';
import Path from '../akun/componenet.path';
import Table from './componenet.table';

const Index = () => {
    const { setMetaData, theme } = useContext(AppContext)
    const [searchParams] = useSearchParams();
    const [collapseFilterSorting, setCollapseFilterSorting] = useState(searchParams.has('date_after') ||
        searchParams.has('date_before')
    )

    useEffect(() => {
        setMetaData({
            title : "Neraca"
        })
    }, [setMetaData])

    return (<>
        <CRow>
            <CCol md={12}>
            <CButton active={collapseFilterSorting} color={'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    setCollapseFilterSorting(x => !x)
                }}> Filter <CIcon className='ms-1' icon={collapseFilterSorting ? cisChevronBottom : cisChevronTop} /></CButton>
            </CCol>
        </CRow>
        <CCollapse visible={collapseFilterSorting}>
            <CRow className='mb-3'>
                <CCol>
                    <CCard>
                        <CCardBody>
                            <FilterSorting />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCollapse>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <Path rootPath='/reportneraca' />
                    </CCardHeader>
                    <CCardBody>
                        <Table />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ScrollToTop smooth component={<CIcon icon={cilArrowTop} />} className={`rounded-circle bg-${theme}`} />
    </>)
}

export default Index