import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import routes from './data/routes';
import Page404 from './pages/Page404';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<React.StrictMode>
                    <App />
                </React.StrictMode>}>
                    {routes.map((route, key) => {
                        return (route.element && <Route
                            key={key}
                            path={route.path}
                            element={route.element}
                        />)
                    })}
                    <Route path="*" element={<Page404 />} />
                </Route>

            </Routes>
        </BrowserRouter>
    </>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
