
import { CFormFeedback, CFormLabel } from "@coreui/react-pro"
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps"
import RiCurrency from "../../components/inputs/Currency"

export function InputNominal({
    id,
    label,
    placeholder,
    invalid = false,
    name,
    value,
    feedbackInvalid,
    onValueChange,
    size = "md"
}: {
    id: string,
    label?: string,
    placeholder?: string,
    feedbackInvalid?: string,
    invalid?: boolean,
    name?: string,
    value?: string | undefined,
    onValueChange?: ((value: string | undefined, name?: string | undefined, values?: CurrencyInputOnChangeValues | undefined) => void) | undefined,
    size?: "md" | "sm"
}) {

    return (
        <>
            {label && <CFormLabel>{label}</CFormLabel>}
            <RiCurrency
                id={id}
                placeholder={placeholder}
                value={value ? value : ""}
                invalid={invalid}
                name={name}
                onValueChange={onValueChange}
                size={size}
            />
            <CFormFeedback invalid>{feedbackInvalid}</CFormFeedback>
        </>
    )
}