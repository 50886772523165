import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"
import moment from "moment"
import RiDatePicker from "../../components/inputs/DatePicker";

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (<CForm className='row gx-3 gy-2 align-items-center'>
        <CCol lg={4}>
            <CFormInput
                value={searchParams.get("inventory") || ''}
                size='sm'
                placeholder="Inventory"
                aria-label="Inventory"
                label={"Inventory"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('inventory')
                    else newsp.set('inventory', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Tanggal Beli After</CFormLabel>
            <RiDatePicker
                id={'date_beli_after'}
                placeholder={'Date Beli After'}
                selected={moment(searchParams.get("date_beli_after"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_beli_after") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_beli_after')
                    else newsp.set('date_beli_after', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Date Beli Before</CFormLabel>
            <RiDatePicker
                id={'date_beli_before'}
                placeholder={'Tanggal Beli Before'}
                selected={moment(searchParams.get("date_beli_before"), "YYYY-MM-DD", true).isValid() ? new Date(searchParams.get("date_beli_before") || "") : null}
                onChange={(date) => {
                    const newsp = createSearchParams(searchParams)
                    if (!moment(moment(date).format('YYYY-MM-DD'), "YYYY-MM-DD", true).isValid()) newsp.delete('date_beli_before')
                    else newsp.set('date_beli_before', moment(date).format('YYYY-MM-DD'))
                    setSearchParams(newsp)
                }}
                size="sm"
                isClearable
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Status</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('is_active') || ''}
                options={[
                    { label: 'Semua', value: "" },
                    { label: 'Aktif', value: 'true' },
                    { label: 'Tidak Aktif', value: 'false' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "Semua") newsp.delete('is_active')
                    else newsp.set('is_active', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || '-id'}
                options={[
                    { label: '# (desc)', value: '-id' },
                    { label: '# (asc)', value: "id" },
                    { label: 'Inventory (desc)', value: '-inventory' },
                    { label: 'Inventory (asc)', value: 'inventory' },
                    { label: 'Date Beli (desc)', value: '-date_beli' },
                    { label: 'Date Beli (asc)', value: 'date_beli' },
                    { label: 'Total Belian (desc)', value: '-total_belian' },
                    { label: 'Total Belian (asc)', value: 'total_belian' },
                    { label: 'Total Pelunasan (desc)', value: '-total_pelunasan' },
                    { label: 'Total Pelunasan (asc)', value: 'total_pelunasan' },
                    { label: 'Total Bunga (desc)', value: '-total_bunga' },
                    { label: 'Total Bunga (asc)', value: 'total_bunga' },

                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "-id") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting