import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"
import moment from "moment"
import RiDatePicker from "../../components/inputs/DatePicker";

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (<CForm className='row gx-3 gy-2 align-items-center'>
        <CCol lg={4}>
            <CFormInput
                value={searchParams.get("caption") || ''}
                size='sm'
                placeholder="Caption"
                aria-label="Caption"
                label={"Hutang"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('caption')
                    else newsp.set('caption', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>

        <CCol lg={2} xs={6}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || '-id'}
                options={[
                    { label: '# (desc)', value: '-id' },
                    { label: '# (asc)', value: "id" },
                    { label: 'Caption (desc)', value: '-caption' },
                    { label: 'Caption (asc)', value: 'caption' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "-id") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting