import { useContext, useEffect, useState } from 'react'
import { CButton, CCard, CCardBody, CCol, CCollapse, CRow } from '@coreui/react-pro';

import ScrollToTop from 'react-scroll-to-top';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AppContext } from '../../contexts/App';
import CIcon from '@coreui/icons-react';
import { cilArrowTop, cilPlus, cisCaretBottom, cisCaretTop } from '@coreui/icons-pro';
import Table from './componenet.table';
import FilterSorting from './componenet.filter.sorting';

export default function Index() {
    const { setMetaData, theme } = useContext(AppContext)
    const [searchParams] = useSearchParams();
    const [collapseFilterSorting, setCollapseFilterSorting] = useState(searchParams.has('transaksi') ||
        searchParams.has('date_after') ||
        searchParams.has('date_before') ||
        searchParams.has('akun') ||
        searchParams.has('buku_pembantu[]') ||
        searchParams.has('is_active') ||
        searchParams.has('nominal_min') ||
        searchParams.has('nominal_max') ||
        searchParams.has('ordering'))
    const navigate = useNavigate()
    const params = useParams();

    useEffect(() => {
        setMetaData({
            title: `Cash Flow`,
        })
    }, [params.id2, setMetaData])

    return (<>
        <CRow>
            <CCol md={12}>
               <CButton className='me-2 mb-2' size='sm' color="primary" onClick={(e) => {
                    navigate({ pathname: "add" })
                }}><CIcon icon={cilPlus} className='me-1' />Add</CButton>

                <CButton active={collapseFilterSorting} color={'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    setCollapseFilterSorting(x => !x)
                }}> Filter & Sorting<CIcon className='ms-1' icon={collapseFilterSorting ? cisCaretBottom : cisCaretTop} /></CButton>
            </CCol>
        </CRow>

        <CCollapse visible={collapseFilterSorting}>
            <CRow className='mb-3'>
                <CCol>
                    <CCard>
                        <CCardBody>
                            <FilterSorting />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCollapse>
        <CRow>
            <CCol>
                <CCard>
                    <CCardBody>
                        <Table />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ScrollToTop smooth component={<CIcon icon={cilArrowTop} />} className={`rounded-circle bg-${theme}`} />
    </>)
}
