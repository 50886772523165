import React, {useContext} from 'react'
import {AppContext} from '../contexts/App';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {INav} from '../types/layout';
import {
    CBadge,
    CImage,
    CNavGroup,
    CNavItem,
    CNavTitle,
    CSidebar,
    CSidebarBrand, CSidebarHeader,
    CSidebarNav,
    CSidebarToggler
} from '@coreui/react-pro';
import nav from '../data/nav';
import sygnet from '../assets/images/layout/sygnet.png'
import logoNegative from '../assets/images/layout/logo-negative.png'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import CIcon from "@coreui/icons-react";
import {cilCloudDownload, cilLayers, cilPuzzle, cilSpeedometer} from "@coreui/icons-pro";

const Sidebar = () => {
    const location = useLocation()
    const { sidebar: { visible, unfoldable, toggleVisible, toggleUnfoldable } } = useContext(AppContext);
    const navigate = useNavigate();

    const navRecursive = (items: INav[]) => {
        return items.map((item, index) => {
            let Component = (item.to && !item.items) ? CNavItem : CNavGroup

            const is_active = item.to !== "/" ? location.pathname.startsWith(item.to || "") : location.pathname === item.to;
            return <Component
                idx={index.toString()}
                className={is_active ? "active" : ""}
                key={index}
                href={item.to}
                toggler={<>{item.icon}{item.name}</>}
                onClick={(e) => {
                    e.preventDefault()
                    if (item.to && !item.items) navigate(item.to)
                }}
            >
                {!item.items && <React.Fragment key={`item_${index}`}>
                    {item.icon}{item.name}
                </React.Fragment>}
                {navRecursive(item.items || [])}
            </Component>
        })
    }

    return (
        <>
            <CSidebar
                position="fixed"
                unfoldable={unfoldable}
                visible={visible}
                // className='bg-dark text-white'
                // colorScheme={'darken'}
                onVisibleChange={x => {
                    if (x !== visible) toggleVisible()
                }}
                className={"border-end"}
            >


                <CSidebarHeader className="border-bottom">
                    <CSidebarBrand className={'mx-auto'} onClick={() => {
                        navigate("/")
                    }}>

                        <div style={{cursor: "pointer"}} className="sidebar-brand-full ">
                            <CImage className={'p-1'} style={{cursor: "pointer"}} src={sygnet} height={32}/>
                            <span className={'align-middle ms-2'}><strong>K</strong><small>euangan</small> <strong>R</strong><small>umah</small> <strong>T</strong><span>angga</span></span>
                            {/*<span className={'text-uppercase fw-bold'}>{process.env.REACT_APP_PUBLIC_SITE_NAME}</span>*/}
                        </div>
                        {/*<CImage style={{ cursor: "pointer" }} className="sidebar-brand-full" src={logoNegative} height={18} />*/}
                        <div style={{cursor: "pointer"}} className="sidebar-brand-narrow">
                            <CImage className={'p-1'} style={{cursor: "pointer"}} src={sygnet} height={32}/>
                        </div>
                        {/*<CImage style={{cursor: "pointer"}} className="sidebar-brand-narrow" src={sygnet} height={24}/>*/}
                    </CSidebarBrand>
                </CSidebarHeader>
                <CSidebarNav>
                    <SimpleBar style={{height: '100%'}}>
                        {navRecursive(nav)}
                    </SimpleBar>
                </CSidebarNav>

                <CSidebarHeader className="border-top">
                    <CSidebarToggler
                        className="d-none d-lg-flex"
                        onClick={toggleUnfoldable}
                    />
                </CSidebarHeader>
            </CSidebar>
        </>
    )
}



export default React.memo(Sidebar)