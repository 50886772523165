import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRetrieve } from '../../hooks/retrieve'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { CPlaceholder, CWidgetStatsD } from '@coreui/react-pro';
import { Chart } from 'react-chartjs-2';
import CIcon from '@coreui/icons-react';
import { cilNotes } from '@coreui/icons-pro';

interface IWidgetBukuPembantu {
    non_sub_count: number
    sub_count: number
    inactive_count: number
}

export default function WidgetBukuPembantu() {
    const navigate = useNavigate()
    const [instance] = useRetrieve<IWidgetBukuPembantu>({ path: "/dashboards/bukupembantu" })

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Legend,
        Title,
        Tooltip
    );

    if (!instance) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<CWidgetStatsD
        style={{ cursor: 'pointer' }}
        onClick={() => {
            navigate({
                pathname: '/bukupembantu'
            })
        }}
        className="mb-3"
        icon={<><CIcon icon={cilNotes} className="my-3 text-white" height={40} /> <span className="text-white ms-3">DATA BUKU PEMBANTU</span></>}
        color={'primary'}
        values={[
            { title: 'Buku Pembantu', value: instance?.non_sub_count },
            { title: 'Sub Buku Pembantu', value: instance?.sub_count },
        ]}
        chart={
            <Chart
                type="bar"
                className="position-absolute w-100 h-100"
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            display: false,
                        },
                    },
                }}

                data={{
                    labels: ['Buku Pembantu', 'Sub Buku Pembantu'],
                    datasets: [
                        {
                            data: [instance?.non_sub_count, instance?.sub_count],
                            borderColor: 'rgba(255,255,255,.55)',
                            backgroundColor: 'rgba(255,255,255,.1)',
                        },
                    ],
                }}
            />
        }
    />)
}
