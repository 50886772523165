import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react-pro'
import { FormAddEdit } from './componenet.form.add.edit'
import { useParams } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AppContext } from '../../contexts/App'
import CIcon from '@coreui/icons-react'
import { cisFile, cisFolder } from '@coreui/icons-pro'

const Edit = ({ isSub }: { isSub: boolean }) => {
    const { setMetaData } = useContext(AppContext)
    const params = useParams();
    const icon = <CIcon icon={isSub ? cisFolder : cisFile} className='me-2' />
    const title = `Edit ${isSub ? 'Sub ' : ''}Buku Pembantu #${params.id}`

    useEffect(() => {
        setMetaData({ title })
    }, [setMetaData, title])

    return (<>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader >
                        <strong>{icon}{title}</strong>
                    </CCardHeader>
                    <CCardBody>
                        <FormAddEdit edit={params.id} isSub={isSub} title={title} />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </>)
}

export default Edit