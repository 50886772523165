
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CPlaceholder, CWidgetStatsD } from '@coreui/react-pro';
import CIcon from '@coreui/icons-react';
import { cilInbox } from '@coreui/icons-pro';
import { useRetrieve } from '../../hooks/retrieve';
import { AppContext } from '../../contexts/App';

interface IWidgetInventory {
    data_count: number
    inactive_count: number
    summary: {
        total_pembelian__sum: string
        total_perawatan__sum: string
        total_penjualan__sum: string
    }
}

export default function WidgetInventory() {
    const navigate = useNavigate()
    const [instance] = useRetrieve<IWidgetInventory>({ path: "/dashboards/inventory" })
    const { eye } = useContext(AppContext)

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Legend,
        Title,
        Tooltip
    );
    if (!instance) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<CWidgetStatsD
        style={{ cursor: 'pointer' }}
        onClick={() => {
            navigate({
                pathname: '/inventory'
            })
        }}
        className="mb-3"
        icon={<><CIcon icon={cilInbox} className="my-3 text-white" height={40} /> <span className="text-white ms-3">INVENTORY</span></>}
        color={'primary'}
        values={[
            { title: 'pembelian', value: `Rp ${eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(Number(instance?.summary.total_pembelian__sum) || 0)}` },
            { title: 'perawatan', value: `Rp ${eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(Number(instance?.summary.total_perawatan__sum) || 0)}` },
            { title: 'penjualan', value: `Rp ${eye === 'close' ? '**********' : new Intl.NumberFormat('id-ID').format(Number(instance?.summary.total_penjualan__sum) || 0)}` },
        ]}
        chart={
            <Bar
                className="position-absolute w-100 h-100"
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        x: {
                            display: false,
                        },
                        y: {
                            display: false,
                        },
                    },
                }}

                data={{
                    labels: ['Pembelian', 'Pemeliharaan', 'Penjualan'],
                    datasets: [
                        {
                            data: [
                                Number(eye === 'close' ? 100: instance?.summary?.total_pembelian__sum),
                                Number(eye === 'close' ? 100: instance?.summary?.total_perawatan__sum),
                                Number(eye === 'close' ? 100: instance?.summary?.total_penjualan__sum)
                            ],
                            borderColor: 'rgba(255,255,255,.55)',
                            backgroundColor: 'rgba(255,255,255,.1)',
                        },
                    ],
                }}
            />
        }
    />)
}
