import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useToast from './toast'
import api from '../libs/api'
import { CButton, CCol, CForm, CFormCheck, CLoadingButton, CModal, CModalBody, CModalHeader, CRow } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cisTrash } from '@coreui/icons-pro'
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, SubmitHandler } from "react-hook-form"
import {GenericErrorHandler} from "../libs/misc";

export default function useModalDelete({ title, path, confirmation }: { title: string, path: string, confirmation: React.ReactNode }): [
    () => JSX.Element,
    () => JSX.Element,
] {
    const [addToast] = useToast()
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)

    const FormSchema = z.object({
        is_confirmed: z.boolean(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
        getValues,
        reset
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: ({ is_confirmed: false })
    })


    const UiButtonDelete = () => <CButton className='float-end' color='danger' onClick={() => {
        setShowModal(true)
    }}><CIcon className='me-2' icon={cisTrash} />Delete</CButton>

    const UiModalDelete = () => <CModal transition portal backdrop={'static'} visible={showModal} alignment='center' onClose={() => {
        setShowModal(false)
        reset({is_confirmed:false})
    }}>
        <CModalHeader><strong>{title}</strong></CModalHeader>
        <CModalBody>
            <CForm
                id='formDelete'
                className="row"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                autoComplete='false'
            >
                <CRow className="mb-3">
                    <CCol md={12} >
                        <CFormCheck
                            label={confirmation}
                            invalid={errors.is_confirmed != null}
                            {...register('is_confirmed')}
                        />
                        <div className="invalid-feedback">{errors.is_confirmed?.message}</div>
                    </CCol>
                </CRow>

                <CRow className='mb-3'>
                    <CCol md={12}>
                        <CLoadingButton color='danger' form='formDelete' loading={isSubmitting} type="submit" className='me-2'>Hapus</CLoadingButton>
                        <CLoadingButton color='secondary' className='me-2' onClick={() => {
                            reset({is_confirmed:false})
                            setShowModal(false)
                        }}>Batalkan</CLoadingButton>
                    </CCol>
                </CRow>

            </CForm>
        </CModalBody>
    </CModal>

    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        if (!getValues("is_confirmed")) {
            setError("is_confirmed", { type: 'custom', message: "Aksi hapus belum terkonfirmasi" })
            return;
        }

        await api.delete(path).then(() => {
            addToast({ color: "success", message: `${title} successed` })
            navigate(-1)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }


    return [UiModalDelete, UiButtonDelete]
}
