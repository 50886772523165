import { CAvatar, CPlaceholder, CWidgetStatsD } from '@coreui/react-pro'
import { useRetrieve } from '../../hooks/retrieve'
import defaultUser from '../../assets/images/layout/default_user.jpg'

interface IWidgetProfile {
    username: string
    name: string
    picture: string
    entries_count: number
}

export default function WidgetUser() {
    const [instance] = useRetrieve<IWidgetProfile>({ path: "/dashboards/user" })
    if (!instance) return (
        <CPlaceholder animation="glow">
            <CPlaceholder xs={12} size="lg" />
        </CPlaceholder>
    )
    return (<CWidgetStatsD
        className="mb-3"
        color="primary"
        icon={<CAvatar src={instance?.picture || defaultUser} size="xl" />}
        // style={{ '--cui-card-cap-bg': '#00aced' }}
        values={[
            { title: instance?.name, value: instance?.username },
            { title: 'Cash Flow Entries', value: instance?.entries_count },
        ]}
        // style={{ cursor: 'pointer' }}
        // onClick={() => {
        //     navigate({
        //         pathname: '/editprofile'
        //     })
        // }}
    />)
}
