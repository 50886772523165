import CIcon from '@coreui/icons-react'
import {
    cisCloudDownload,
    cisFilterPhoto,
    cisGraph,
    cisKey,
    cisNotes,
    cisPaperPlane,
    cisSpeedometer,
} from '@coreui/icons-pro'
import { INav } from '../types/layout'


const nav : INav[] = [
    {
        name: 'Dashboard',
        to: '/',
        icon: <CIcon icon={cisSpeedometer} customClassName="nav-icon" />,
    },
    {
        name: 'Data',
        icon: <CIcon icon={cisNotes} customClassName="nav-icon" />,
        items: [
            {
                name: 'Akun',
                to: '/akun',
            },
            {
                name: 'Buku Pembantu',
                to: '/bukupembantu',
            },
            {
                name: 'Hutang',
                to: '/hutang',
            },
            {
                name: 'Piutang',
                to: '/piutang',
            },
            {
                name: 'Inventory',
                to: '/inventory',
            },
            {
                name: 'Beban',
                to: '/beban',
            },
            {
                name: 'Template',
                to: '/template',
            },
        ],
    },
    {
        name: 'Cash Flow',
        to: '/cashflow',
        icon: <CIcon icon={cisPaperPlane} customClassName="nav-icon" />,
    },
    // {
    //     name: 'Asset',
    //     to: '/asset',
    //     icon: <CIcon icon={cisInbox} customClassName="nav-icon" />,
    // },
    // {
    //     name: 'Pinjaman',
    //     icon: <CIcon icon={cisBank} customClassName="nav-icon" />,
    //     items: [
    //         {
    //             name: 'Hutang',
    //             to: '/hutang',
    //         },
    //         {
    //             name: 'Piutang',
    //             to: '/piutang',
    //         },
    //     ],
    // },
    {
        name: 'Report',
        icon: <CIcon icon={cisGraph} customClassName="nav-icon" />,
        items: [
            {
                name: 'Neraca',
                to: '/reportneraca',
            },
            {
                name: 'Graph',
                to: '/reportgraph',
            },
            {
                name: 'Buku Pembantu',
                to: '/reportbukupembantu',
            },
        ],
    },
    {
        name: 'Image',
        to: '/image',
        icon: <CIcon icon={cisFilterPhoto} customClassName="nav-icon" />,
    },
    {
        name: 'Credential',
        to: '/credential',
        icon: <CIcon icon={cisKey} customClassName="nav-icon" />,
    },
    {
        name: 'Backup',
        to: '/backup',
        icon: <CIcon icon={cisCloudDownload} customClassName="nav-icon" />,
    },
]

export default nav
