import { CButton, CCol, CForm, CFormInput, CFormLabel, CFormSelect } from "@coreui/react-pro"
import { createSearchParams, useSearchParams } from "react-router-dom"
import { IOption } from "../../types/inputs/select";
import RiSelect from "../../components/inputs/Select";
import {useContext} from "react";
import {AppContext} from "../../contexts/App";

const FilterSorting = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {theme}= useContext(AppContext)

    return (<CForm className='row gx-3 gy-2 align-items-center'>
        <CCol lg={4}>
            <CFormInput
                value={searchParams.get("name") || ''}
                size='sm'
                placeholder="Akun & Sub Akun"
                aria-label="Name"
                label={"Akun & Sub Akun"}
                onChange={(e => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === undefined || e.target.value === "") newsp.delete('name')
                    else newsp.set('name', e.target.value || "")
                    setSearchParams(newsp)
                })}
            />
        </CCol>
        <CCol lg={4}>
            <RiSelect
                type={"akun"}
                isSub="true"
                placeholder='Sub Akun'
                label='Parent'
                size='sm'
                value={parseInt(searchParams.get('parent') || "")}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e) {
                        const data = e as IOption
                        newsp.set('parent', data.value.toString())
                    } else {
                        newsp.delete('parent')
                    }
                    setSearchParams(newsp)
                }}
                theme={theme}
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Type</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('is_sub') || ''}
                options={[
                    { label: 'Semua', value: "" },
                    { label: 'Akun', value: 'false' },
                    { label: 'Sub Akun', value: 'true' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "Semua") newsp.delete('is_sub')
                    else newsp.set('is_sub', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={2} xs={6}>
            <CFormLabel>Status</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('is_active') || ''}
                options={[
                    { label: 'Semua', value: "" },
                    { label: 'Aktif', value: 'true' },
                    { label: 'Tidak Aktif', value: 'false' },
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "Semua") newsp.delete('is_active')
                    else newsp.set('is_active', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={3}>
            <CFormLabel>Sort By</CFormLabel>
            <CFormSelect
                size="sm"
                value={searchParams.get('ordering') || 'number'}
                options={[
                    { label: 'Akun & Sub Akun (asc)', value: 'number' },
                    { label: 'Akun & Sub Akun (desc)', value: '-number' },
                    
                ]}
                onChange={(e) => {
                    const newsp = createSearchParams(searchParams)
                    if (e.target.value === "number") newsp.delete('ordering')
                    else newsp.set('ordering', e.target.value)
                    setSearchParams(newsp)
                }}
            />
        </CCol>
        <CCol lg={12}>
            <CButton className="me-2 mt-2" size="sm" color="secondary" onClick={() => {
                setSearchParams(createSearchParams())
            }}>Reset</CButton>
        </CCol>
    </CForm>)
}

export default FilterSorting