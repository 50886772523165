import { useEffect, useState } from 'react'
import api from '../libs/api'
import useToast from './toast'
import {GenericErrorHandler} from "../libs/misc";

export function useRetrieve<A>({path} : {path : string}): [
    A | undefined , 
    boolean,
]{
    const [instance, setInstance] = useState<A| undefined>()
    const [loading, setLoading] = useState(false)
    const [addToast] = useToast()
    useEffect(() => {
        setLoading(true)
        api.get(path).then(onfulfilled => {
            setInstance(onfulfilled.data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason,addToast})
        })
    }, [addToast, path])

    return [instance, loading]
}