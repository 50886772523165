import {cilAccountLogout, cilContrast, cilMoon, cilSun} from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import {
    CAvatar,
    CButton, CButtonGroup,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter
} from "@coreui/react-pro";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import defaultUser from '../assets/images/layout/default_user.jpg'
import { AppContext } from "../contexts/App";
import useToast from "../hooks/toast";
import api from "../libs/api";

export default function HeaderDropdownUserLogin() {
    const [showModalLogout, setshowModalLogout,] = useState(false)
    const { user, theme, setMode, mode  } = useContext(AppContext)
    return (
        <>
            <CDropdown variant="nav-item">
                <CDropdownToggle caret={false} >
                    <CAvatar src={user?.profile?.picture || defaultUser} />
                </CDropdownToggle>
                <CDropdownMenu>
                    {/*<CDropdownItem style={{ cursor: 'pointer' }} onClick={(e) => {*/}
                    {/*    e.stopPropagation()*/}
                    {/*}}>*/}
                    {/*    <CButtonGroup size = {'sm'} className={'w-100'} >*/}
                    {/*        <CButton color={theme === 'dark' ? 'light' : 'dark'} title={'Light Mode'} active={mode === 'light'} variant="outline" onClick={(e) => {*/}
                    {/*            setMode('light')*/}
                    {/*            e.stopPropagation()*/}
                    {/*        }}><CIcon icon={cilSun} /></CButton>*/}
                    {/*        <CButton color={theme === 'dark' ? 'light' : 'dark'} title={'Dark Mode'} active={mode === 'dark'} variant="outline" onClick={(e) => {*/}
                    {/*            setMode('dark')*/}
                    {/*            e.stopPropagation()*/}
                    {/*        }}><CIcon icon={cilMoon}/></CButton>*/}
                    {/*        <CButton color={theme === 'dark' ? 'light' : 'dark'} title={'Auto Mode'} active={mode === null} variant="outline" onClick={(e) => {*/}
                    {/*            setMode(null)*/}
                    {/*            e.stopPropagation()*/}
                    {/*        }}><CIcon icon={cilContrast} /></CButton>*/}
                    {/*    </CButtonGroup>*/}
                    {/*</CDropdownItem>*/}
                    <CDropdownItem
                        className='text-danger'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            setshowModalLogout(showModalLogout ? false : true)
                        }}
                    >
                        <CIcon icon={cilAccountLogout} className="me-2" />
                        Keluar
                    </CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
            <ModalLogout
                visible={showModalLogout}
                setVisible={setshowModalLogout}
            />
        </>
    )
}


function ModalLogout({ visible, setVisible }: { visible: boolean, setVisible: (x: boolean) => void }) {
    const navigate = useNavigate()
    const [addToast] = useToast()
    const [loading, setLoading] = useState(false)

    return (
        <CModal portal backdrop={'static'} size='sm' alignment='center' visible={visible} onClose={() => { setVisible(false) }}>
            <CModalBody>
                <>Anda yakin ingin keluar?</>
            </CModalBody>
            <CModalFooter>
                <CButton disabled={false} color="secondary" onClick={() => { setVisible(false) }}>
                    Tidak
                </CButton>
                <CLoadingButton loading={loading} color='danger'
                    onClick={() => {
                        setLoading(true)
                        api.post('/auth/logout').then(addToast => {


                        }).finally(() => {
                            addToast({ color: "success", message: "Logout berhasil" })
                            localStorage.removeItem('token')
                            setVisible(false)
                            setLoading(false)
                            navigate(0)
                        });
                    }}
                >
                    Ya, Keluar!
                </CLoadingButton>
            </CModalFooter>
        </CModal>
    )

}