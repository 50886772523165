import { cisImage } from '@coreui/icons-pro'
import CIcon from '@coreui/icons-react'
import { CPopover } from '@coreui/react-pro'
import { IImage } from '../../types/entities/image';

export default function PopoverImages({ datas }: { datas: IImage[] }) {

    if (datas.length === 0) return (<></>);
    return (<CPopover
        content={`${datas.length} Image${datas.length > 0 ?"s": ""}`}
        // placement="top"
        trigger={['click']}
    >
        <CIcon className='ms-2' icon={cisImage} />
    </CPopover>)
}

